import React from "react";
import styled from "styled-components";
import CaseStudiesIntroduction from "../CaseStudiesIntroduction";
import CaseStudiesCard from "../CaseStudiesCard";
import ColumnLayout from "../ColumnLayout";
import Links from "../../../Links";
import EssentialsNew from "../../../../img/casestudy/design-systems/design-system-essentials-new.jpg";
import Rule from "../../../Rule";
import Heading from "../../../Heading";

const TextEmphasis = styled.strong`
  color: #dbdbdb;
`;

const QuoteImage = styled.img`
  width: 100%;
  height: auto;
`;

const Quote = styled.div`
  background: #2f2f2f;
  border-radius: 22px;
  box-shadow: 0 34px 30px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

const QuoteInner = styled.div`
  padding: var(--spacing-x-medium) var(--spacing-large) var(--spacing-small)
    var(--spacing-x-large);
  h3 {
    position: relative;
    &:before {
      content: open-quote;
      font-family: "Gentium Book Basic", serif;
      position: absolute;
      left: -4rem;
      font-size: 5rem;
      color: #5d5d5d;
      font-weight: bold;
      top: -2rem;
    }
  }
`;

const CaseStudyLinks = styled(Links)`
  color: white;
  border-color: rgba(255, 255, 255, 0.2);
  &:hover {
    color: #ff73df;
  }
`;

const Introduction = (
  <CaseStudiesCard id="maturefeelix">
    <CaseStudiesIntroduction>
      <Heading variant="larger">Maturing Feelix</Heading>
      <p>
        In early 2018, MYOB started the journey on a significant project, that
        was to shape the future for MYOB. An essential part of this project was
        to form a funded design system team, which allows us to provide tools
        that help teams build quality and consistent experiences efficiently.
        With the formation of this team, I had an excellent opportunity to help
        shape the future vision of the{" "}
        <Links
          href="https://feelix.myob.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Feelix design system
        </Links>
        .
      </p>
      <Rule spacing="medium" inverted />
    </CaseStudiesIntroduction>
    <ColumnLayout
      gridGap={{ lg: "var(--spacing-xx-large)" }}
      gridTemplateColumns={{ lg: "1fr 1.6fr" }}
    >
      <div>
        <Heading variant="smaller" mb="small" mt="clear" color="#ff73df">
          My Feelix involvement
        </Heading>
        <ul>
          <li>
            Leading and ensuring components follow the{" "}
            <TextEmphasis>atomic design principles</TextEmphasis>
          </li>
          <li>
            Restructuring the Design system to use{" "}
            <TextEmphasis>scalable and modular CSS</TextEmphasis>
          </li>
          <li>
            Introduced and applied{" "}
            <TextEmphasis>Responsive web design</TextEmphasis> into the Feelix
            design system foundation and component range
          </li>
          <li>
            Embedding a <TextEmphasis>Baseline grid</TextEmphasis> to achieve
            vertical rhythm to the core of Feelix codebase
          </li>
          <li>
            Adding a <TextEmphasis>typographic scale</TextEmphasis> using CSS
          </li>
          <li>
            Designed the Feelix design token{" "}
            <TextEmphasis>structure and naming convention</TextEmphasis>
          </li>
          <li>
            Leading the direction of supplying{" "}
            <TextEmphasis>React layout components</TextEmphasis> instead of
            using Bootstrap classes to create a layout
          </li>
          <li>
            Driving the design direction and experience for{" "}
            <TextEmphasis>Feelix page templates</TextEmphasis>
          </li>
          <li>
            Increasing the <TextEmphasis>usage guidelines</TextEmphasis> and
            improving the component{" "}
            <TextEmphasis>technical guidelines</TextEmphasis>
          </li>
          <li>
            <TextEmphasis>Automate the designer UI-Kit</TextEmphasis> to be a
            1:1 reflection of what is in the Feelix Design System offering.
          </li>
        </ul>
      </div>
      <div>
        <Quote>
          <QuoteImage
            src={EssentialsNew}
            alt="MYOB Incite: New Essentials using Feelix"
            height="236"
          />
          <QuoteInner>
            <Heading variant="medium">
              Essentials is fully mobile responsive. Was very cool to see the
              desktop screen resize to a tablet and then again to a mobile.
              Props, MYOB
            </Heading>

            <CaseStudyLinks
              target="_blank"
              rel="noopener noreferrer"
              href="https://digitalfirst.com/myobs-new-ceo-is-building-a-new-myob-and-this-time-its-war/"
            >
              digitalfirst.com - Sholto Macpherson | Year: 2020
            </CaseStudyLinks>
          </QuoteInner>
        </Quote>
      </div>
    </ColumnLayout>
  </CaseStudiesCard>
);

export default Introduction;
