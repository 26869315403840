import React from "react";
import styled from "styled-components";
import Icons from "../../../Icons";
import Badge from "../../../Badge";
import ProjectTitle from "../ProjectTitle";
import ProjectSection from "../ProjectSection";
import ProjectSectionSkills from "../ProjectSectionSkills";
import ProjectDescription from "../ProjectDescription";
import ProjectHeroImage from "../ProjectHeroImage";
import TimeCapsuleImage1 from "../../../../img/time-capsule-1.jpg";
import TimeCapsuleImage2 from "../../../../img/time-capsule-2.jpg";

const TimeCapsuleProjectSection = styled(ProjectSection)`
  background: #15967d;
`;

function TimeCapsule() {
  return (
    <TimeCapsuleProjectSection>
      <ProjectTitle title="Time Capsule" />
      <ProjectSectionSkills>
        <Badge small title="UI Design">
          <Icons type="rgba-small" />
        </Badge>
        <Badge small title="UX Design">
          <Icons type="ux-small" />
        </Badge>
        <Badge small title="Xcode">
          <Icons type="xcode-small" />
        </Badge>
      </ProjectSectionSkills>
      <ProjectDescription>
        Based on the user's location, they can view modern-day landmarks during
        different periods. My goal is to be more involved with the
        implementation of my designs for iOS, so I can make correct design
        adjustments that were needed during development.
      </ProjectDescription>
      <ProjectHeroImage
        mobile
        srcMobile1={TimeCapsuleImage1}
        srcMobile2={TimeCapsuleImage2}
      />
    </TimeCapsuleProjectSection>
  );
}

export default TimeCapsule;
