import React from "react";
import styled from "styled-components";
import CaseStudyHeading from "./CaseStudiesHeading";
import { codeReminders } from "./data";
import CaseStudiesCard from "./CaseStudiesCard";
import Heading from "../../Heading";
import Box from "../../Box";
import Rule from "../../Rule";
import NoHooks from "../../../img/noHooks.png";
import Hooks from "../../../img/hooks.png";

const CodeElementBlock = styled.section`
  display: block;
  width: 100%;
  padding: var(--spacing-small);
  background: var(--black-palette);
  border-radius: var(--radius-card);
  margin-bottom: var(--spacing-clear);
`;

const CodeElement = styled.code`
  margin-bottom: var(--spacing-small);
  display: block;
  &:last-child {
    margin-bottom: var(--spacing-clear);
  }
  &:empty {
    display: none;
  }
`;

const ImgElementBlock = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--spacing-large);
  width: 100%;
  background: none;
  border-radius: var(--radius-card);
  margin-bottom: var(--spacing-clear);
`;

const ImgElement = styled.img`
  margin-bottom: var(--spacing-small);
  display: block;
  width: 100%;
  padding: var(--spacing-small);
  background: #2b273d;
  border-radius: 12px;
  &:last-child {
    margin-bottom: var(--spacing-clear);
  }
`;

const ParagraphElement = styled.p`
  max-width: 64ch;
`;

const CaseStudiesCodeReminders = () => {
  return (
    <React.Fragment>
      <CaseStudyHeading title="Code reminders" />
      {codeReminders.map((content) => (
        <CaseStudiesCard spacing="large" key={content.id}>
          <Heading variant="large">{content.title}</Heading>
          <p>{content.description}</p>
          <CodeElementBlock>
            <CodeElement>{content.example.comment}</CodeElement>
            <CodeElement>{content.example.code}</CodeElement>
          </CodeElementBlock>
        </CaseStudiesCard>
      ))}
      <CaseStudiesCard spacing="large">
        <Heading variant="large">Handling state in React</Heading>
        <ParagraphElement>
          Previously if you needed to access state in your component, you would
          need to utilise a class component and add additional code to
          initialise state with the constructor. With the introduction of React
          Hooks, you can use your function-based components and keep your code
          lean and straightforward.
        </ParagraphElement>
        <Rule inverted />
        <ImgElementBlock>
          <Box marginBottom="clear">
            <Heading variant="medium" marginBottom="clear">
              Old
            </Heading>
            <p>Handle state with the constructor method.</p>
            <ImgElement src={NoHooks} />
          </Box>
          <Box marginBottom="clear">
            <Heading variant="medium" marginBottom="clear">
              New
            </Heading>
            <p>Handle state with Hooks.</p>
            <ImgElement src={Hooks} />
          </Box>
        </ImgElementBlock>
      </CaseStudiesCard>
    </React.Fragment>
  );
};

export default CaseStudiesCodeReminders;
