import styled from "styled-components";
import { space, layout, color, typography, flexbox } from "styled-system";

const Box = styled.div`
  box-sizing: border-box;
  min-width: 0;
  ${space}
  ${layout}
  ${color}
  ${flexbox}
  ${typography}
`;

export default Box;
