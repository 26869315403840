import React from "react";
import styled from "styled-components";
import Image from "../../../Image";
import CaseStudiesCard from "../CaseStudiesCard";
import Frustration from "../../../../img/casestudy/design-systems/design-process-frustration.png";
import Rule from "../../../Rule";
import Heading from "../../../Heading";
import Quote from "../../../Quote";

const BlogContent = styled.div`
  max-width: 75rem;
  margin: var(--spacing-medium) auto;
  p,
  ul,
  ol {
    font-size: var(--font-size-heading-x-small);
    max-width: 68ch;
  }
`;

const BlogImage = styled(Image)`
  padding: var(--spacing-medium) 0;
  background: #141414;
  img {
    width: 50%;
    margin: 0 auto;
    display: block;
  }
`;

const Introduction = (
  <CaseStudiesCard id="reduceconfusion">
    <BlogContent>
      <Heading variant="larger" maxWidth="30ch" marginBottom="larger">
        Improve relationships between Design & Development
      </Heading>
      <p>
        My name is Michael Trilford, and I’ve been working in a role known as a
        Design Technologist for the past two and a half years, leading the
        Design System thinking at MYOB within Product Delivery.
      </p>
      <p>
        During my time working with the Product and Delivery groups, I have
        observed opportunities to improve help improve relationships between
        Design and Development. I want to share the observations, and provide
        thoughts on how we, as Product Designers can make some small tweaks in
        our design and collaboration processes to improve the relationships
        between Design and Developers significantly.
      </p>
      <Rule spacing="medium" inverted />
      <Heading variant="large" maxWidth="30ch">
        Reduce developer confusion during hand-over
      </Heading>
      <p>
        My role allows me to consult both Product and Delivery on Design System
        related questions. Through this consultation, I have gained insight into
        the Product Delivery Teams, as they are often confused around an
        experience they are building because a component they need is not in the
        Design System.
      </p>
      <BlogImage dark src={Frustration} />
      <Heading variant="small">
        For example, the designed experience has:
      </Heading>
      <ul>
        <li>
          a Design System adjustment which doesn’t match the Design System; or
        </li>
        <li>an unbuilt component is included within the design artefact.</li>
      </ul>
      <p>
        Often these adjustments or new additions are not disclosed to the
        Product Delivery Team during the planning and only discovered once the
        work begins.
      </p>
      <Quote mt="larger" mb="larger" borderColor="var(--gloom-grey-palette)">
        Our team is looking for this component in the Design System, why can’t I
        find it?
      </Quote>
      <p>
        The example above provides insight into how a simple deviation from the
        Design System or including a new component without a proper hand-over
        can raise some confusion among the Product Delivery Team members.
      </p>
      <p>
        I have seen this confusion impact the teams productivity directly, as
        the group is using their time to unravel the mystery of what is in the
        Design System, and what is not. Unfortunately, the confusion is met with
        frustration, as often the experiences don’t exist.
      </p>
      <Heading variant="small">The Product Delivery Team will have to:</Heading>
      <ul>
        <li>find a way to complete the task themselves; or</li>
        <li>
          revisit the designed experience to use what is in the Design System
        </li>
      </ul>
      <Heading variant="small">
        Either option can be frustrating for all involved:
      </Heading>
      <ul>
        <li>as the task has grown in scope; or</li>
        <li>the Product Designer needs to revisit an existing design.</li>
      </ul>
      <p>
        A simple way to avoid unknown requirements or rework of the design is to
        learn about the constraints sooner in the process. To ensure Product
        Delivery Teams understand what is required to build the experience, you
        can ask yourself some simple questions from the hand-over checklist
        below. These questions will help you drive the conversation with your
        Product Delivery Team around the details for the experience you intend
        to build.
      </p>
      <Heading variant="small">Hand-over checklist:</Heading>
      <ol>
        <li>
          Are there any Design System adjustments in the workflow/task you have
          created?
        </li>
        <li>
          Do you have any newly designed components in the workflow/task that
          are not in the Design System?
        </li>
      </ol>
      <p>
        If a question is answered as ‘Yes’, it is important to highlight these
        critical areas of the experience to your Product Delivery Team so that
        the team can have a conversation about the work and make a plan.
      </p>
      <p>
        If you’re indicating a Design System adjustment is required for the
        designed experience, it is essential to know who can adjust the Design
        System within your organisation.
      </p>
      <Heading variant="small">Some organisations may have:</Heading>
      <ul>
        <li>a dedicated Design System team with existing priorities; or</li>
        <li>
          the responsibility is shared across teams to adjust the Design System.
        </li>
      </ul>
      <p>
        Depending on that governance model, it may impact the ability for your
        Product Delivery Team to deliver on their forecasted timeline as
        expected.
      </p>
      <Heading variant="small">If there is:</Heading>
      <ul>
        <li>
          work required outside of your Product Delivery Team, there is no
          guarantee that work will be completed in time to release the team
          objective to market; or
        </li>
        <li>
          if your Product Delivery Team is expected to adjust the Design System,
          please check with the group involved first, as it may not be a task
          they are comfortable or have the specific skills required to complete
          the job.
        </li>
        <p>
          Once it is known who can adjust the Design System, it is now time to
          understand the effort involved with adjusting a Design System
          decision.
        </p>
      </ul>
      <Rule spacing="medium" inverted />
      <Heading variant="large">
        Understanding of the effort that is required to adjust the Design System
      </Heading>
      <Heading variant="small">Stages of adjusting a Design System:</Heading>
      <ol>
        <li>Designer designs the Design System change</li>
        <li>Design System implements the change</li>
        <li>Design System regression tests the change</li>
        <li>Design System releases a new version</li>
        <li>Product Delivery Team upgrades its Design System version</li>
        <li>Product Delivery Team regression tests the update</li>
        <li>Product Delivery Team can now start the objective</li>
        <li>Product Delivery Team can complete the experience</li>
        <li>Product Delivery Team releases the experience to market</li>
      </ol>
      <p>
        From start to finish, a Design System change can take 1–2 weeks, then
        best case scenario it will take another 1–2 weeks for the website or
        application to upgrade their version of the Design System. Worst case is
        the application is highly complicated and have many dependencies, and
        the time on task to upgrade could change from weeks to a month or more.
      </p>
      <p>
        Until that upfront work is completed, is when the Product Delivery Team
        can even consider finalising the experience and releasing value to the
        market.
      </p>
      <Heading variant="small">
        This insight may allow you to reframe and ask whether:
      </Heading>
      <ul>
        <li>is the Design System change necessary; or</li>
        <li>is the existing Design System suitable for now?</li>
      </ul>
      <p>
        Please remember, Design System changes can be made outside of a Product
        Delivery release, as the Design System is product agnostic.
      </p>
      <Heading variant="small">The adjustments can either be:</Heading>
      <ul>
        <li>
          briefed to the Design System team to review and complete the work in a
          separate stream of work. The next time your application goes through
          their upgrade cycle, those adjustments will be inherited into the past
          released experiences automatically; or
        </li>
        <li>
          if your team is responsible for Design System upgrades, the team can
          plan for the work as an iteration to the main feature release.
        </li>
      </ul>
      <Rule spacing="medium" inverted />
      <Heading variant="large">In closing</Heading>
      <p>
        By reducing confusion during hand-over and gaining an understanding of
        the effort that is required to adjust the Design System, we, as Product
        Designers, can drive a more collaborative/supportive/positive
        relationship between Product Design and Development. This new dynamic
        provides the Product Delivery Team with an opportunity to deliver
        faster, and learn from those experiences more regularly, gaining more
        time to fine-tune the end experience that benefits the end customer.
      </p>
      <Heading variant="small">Please remember to:</Heading>
      <ul>
        <li>
          Highlight key areas of the designed experience that don’t exist in the
          Design System.
        </li>
        <li>
          Use the Design System version your Product Delivery Team is tied to,
          as updating and upgrading a Design System is expensive.
        </li>
        <li>
          When you do find an opportunity to improve the Design System, separate
          the concerns and introduce the improvement outside the current stream
          of work.
        </li>
      </ul>
    </BlogContent>
  </CaseStudiesCard>
);

export default Introduction;
