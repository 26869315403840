import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { variant, space, layout, typography, color } from "styled-system";

const HeadingGlobal = `
  width: 100%;
  margin-bottom: var(--spacing-medium);
  font-weight: normal;
`;

const HeadingLargest = styled.h1`
  ${HeadingGlobal}
  ${variant({
    variants: {
      largest: {
        fontFamily: (theme) => theme.fonts.display,
        fontSize: (theme) => theme.fontSizes.largest,
        lineHeight: (theme) => theme.lineHeights.largest,
      },
    },
  })}
  ${space}
  ${layout}
  ${typography}
  ${color}
`;

const HeadingLarger = styled.h1`
  ${HeadingGlobal}
  ${variant({
    variants: {
      larger: {
        fontFamily: (theme) => theme.fonts.display,
        fontSize: (theme) => theme.fontSizes.larger,
        lineHeight: (theme) => theme.lineHeights.larger,
      },
    },
  })}
  ${space}
  ${layout}
  ${typography}
  ${color}
`;

const HeadingLarge = styled.h2`
  ${HeadingGlobal}
  ${variant({
    variants: {
      large: {
        fontFamily: (theme) => theme.fonts.display,
        fontSize: (theme) => theme.fontSizes.large,
        lineHeight: (theme) => theme.lineHeights.large,
      },
    },
  })}
  ${space}
  ${layout}
  ${typography}
  ${color}
`;

const HeadingMedium = styled.h3`
  ${HeadingGlobal}
  ${variant({
    variants: {
      medium: {
        fontFamily: (theme) => theme.fonts.display,
        fontSize: (theme) => theme.fontSizes.medium,
        lineHeight: (theme) => theme.lineHeights.medium,
      },
    },
  })}
  ${space}
  ${layout}
  ${typography}
  ${color}
`;

const HeadingSmall = styled.h4`
  ${HeadingGlobal}
  ${variant({
    variants: {
      small: {
        fontFamily: (theme) => theme.fonts.display,
        fontSize: (theme) => theme.fontSizes.small,
        lineHeight: (theme) => theme.lineHeights.small,
        marginTop: (theme) => theme.space.larger,
      },
    },
  })}
  ${space}
  ${layout}
  ${typography}
  ${color}
`;

const HeadingSmaller = styled.h5`
  ${HeadingGlobal}
  ${variant({
    variants: {
      smaller: {
        fontFamily: (theme) => theme.fonts.display,
        fontSize: (theme) => theme.fontSizes.smaller,
        lineHeight: (theme) => theme.lineHeights.smaller,
        marginTop: (theme) => theme.space.larger,
      },
    },
  })}
  ${space}
  ${layout}
  ${typography}
  ${color}
`;

const HeadingSmallest = styled.h6`
  ${HeadingGlobal}
  ${variant({
    variants: {
      smallest: {
        fontFamily: (theme) => theme.fonts.display,
        fontSize: (theme) => theme.fontSizes.smallest,
        lineHeight: (theme) => theme.lineHeights.smallest,
      },
    },
  })}
  ${space}
  ${layout}
  ${typography}
  ${color}
`;

const Heading = ({ children, variant, ...props }) => {
  return variant === "largest" ? (
    <HeadingLargest {...props} variant={variant}>
      {children}
    </HeadingLargest>
  ) : variant === "larger" ? (
    <HeadingLarger {...props} variant={variant}>
      {children}
    </HeadingLarger>
  ) : variant === "large" ? (
    <HeadingLarge {...props} variant={variant}>
      {children}
    </HeadingLarge>
  ) : variant === "medium" ? (
    <HeadingMedium {...props} variant={variant}>
      {children}
    </HeadingMedium>
  ) : variant === "small" ? (
    <HeadingSmall {...props} variant={variant}>
      {children}
    </HeadingSmall>
  ) : variant === "smaller" ? (
    <HeadingSmaller {...props} variant={variant}>
      {children}
    </HeadingSmaller>
  ) : variant === "smallest" ? (
    <HeadingSmallest {...props} variant={variant}>
      {children}
    </HeadingSmallest>
  ) : (
    <HeadingLarger variant={variant}>{children}</HeadingLarger>
  );
};

Heading.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
};

Heading.defaultProps = {
  children: undefined,
  variant: "larger",
};

export default Heading;
