import React from "react";
import styled from "styled-components";
import Links from "../Links";
import Container from "../Container";

const FooterElement = styled.footer`
  text-align: center;
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
  margin-bottom: 0;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--spacing-medium) 0 0 0;
`;

const FooterText = styled.p`
  width: auto;
  color: var(--white-palette-transparent-50);
  margin-bottom: 0;
`;

const Footer = () => {
  return (
    <FooterElement>
      <Container fluid center>
        <Links button href="#back-to-top">
          Back to top
        </Links>
        <FooterWrapper>
          <FooterText>
            Designed &amp; Built by Mike Trilford - © 2024
          </FooterText>
        </FooterWrapper>
      </Container>
    </FooterElement>
  );
};

export default Footer;
