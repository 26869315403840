import React from "react";
import styled from "styled-components";
import Icons from "../../../Icons";
import Badge from "../../../Badge";
import ProjectTitle from "../ProjectTitle";
import ProjectSection from "../ProjectSection";
import ProjectSectionSkills from "../ProjectSectionSkills";
import ProjectDescription from "../ProjectDescription";

const CodepenProjectSection = styled(ProjectSection)`
  background: #1597c5;
`;

const CodepenIframe = styled.iframe`
  width: 100%;
  display: block;
  height: 45.2rem;
  padding: 5.106382978723%;
  padding-bottom: 0px;
  min-height: 14.41667rem;
  background: #000000;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  margin-bottom: 0;
  border: none;
`;

function CodepenToggleColor() {
  return (
    <CodepenProjectSection>
      <ProjectTitle
        title="Toggle Contrast"
        tag="Codepen"
        tagLink="https://codepen.io/trilm/pen/EaKxer"
      />
      <ProjectSectionSkills>
        <Badge small title="UI Design">
          <Icons type="rgba-small" />
        </Badge>
        <Badge small title="HTML">
          <Icons type="html-small" />
        </Badge>
        <Badge small title="Sass">
          <Icons type="sass-small" />
        </Badge>
        <Badge small title="JQUERY">
          <Icons type="jquery-small" />
        </Badge>
      </ProjectSectionSkills>
      <ProjectDescription>
        Over the years, I have been experimenting and using Codepen to try out
        new ideas in the hope to utilise the explorations in future work. This
        contrast toggle was an exploration of how MYOB could introduce this
        concept into our product family.
      </ProjectDescription>
      <CodepenIframe
        height="452"
        scrolling="no"
        title="EaKxer"
        src="https://codepen.io/trilm/embed/EaKxer?height=452&theme-id=dark&default-tab=result"
        frameborder="no"
        allowtransparency="true"
        allowfullscreen="true"
      >
        See the Pen <a href="https://codepen.io/trilm/pen/EaKxer">EaKxer</a> by
        Michael Trilford (<a href="https://codepen.io/trilm">@trilm</a>) on{" "}
        <a href="https://codepen.io">CodePen</a>.
      </CodepenIframe>
    </CodepenProjectSection>
  );
}

export default CodepenToggleColor;
