import React from "react";
import styled from "styled-components";
import Icons from "../../../Icons";
import Badge from "../../../Badge";
import Links from "../../../Links";
import ProjectTitle from "../ProjectTitle";
import ProjectSection from "../ProjectSection";
import ProjectSectionSkills from "../ProjectSectionSkills";
import ProjectDescription from "../ProjectDescription";
import ProjectHeroImage from "../ProjectHeroImage";
import SenseiImage from "../../../../img/sensei.png";

const MuiProjectSection = styled(ProjectSection)`
  background: #1f491a;
`;

const SenseiLink = styled(Links)`
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  &:hover,
  &:focus {
    color: white;
    border-bottom: none;
  }
  &:focus {
    outline-color: white;
  }
`;

function Sensei() {
  return (
    <MuiProjectSection>
      <ProjectTitle title="Sensei" />
      <ProjectSectionSkills>
        <Badge small title="UI Design">
          <Icons type="rgba-small" />
        </Badge>
        <Badge small title="HTML">
          <Icons type="html-small" />
        </Badge>
        <Badge small title="CSS">
          <Icons type="css-small" />
        </Badge>
        <Badge tooltip="large" small title="React">
          <Icons type="react-small" />
        </Badge>
        <Badge small title="UX Design">
          <Icons type="ux-small" />
        </Badge>
      </ProjectSectionSkills>
      <ProjectDescription>
        <SenseiLink href="https://sensei.study" target="_blank" color="white">
          Sensei.study
        </SenseiLink>{" "}
        is an innovative Japanese language-learning app that helps you master
        real-life phrases through engaging lessons and authentic audio clips. It
        also explores the new light-dark CSS feature, offering both light and
        dark themes to match user preferences and improve readability across
        different lighting conditions.
      </ProjectDescription>

      <ProjectHeroImage src={SenseiImage} desktop />
    </MuiProjectSection>
  );
}

export default Sensei;
