import React from "react";

const Icons = (props) => {
  return (
    <svg className={props.className}>
      <use href={`#${props.type}`} />
    </svg>
  );
};
export default Icons;
