import React from "react";
import styled, { css } from "styled-components";
import { space } from "styled-system";

const RuleElement = styled.hr`
  border-top: var(--border-thin);
  margin-bottom: calc(var(--spacing-medium) - 1px);
  border-top: ${(props) =>
    props.inverted ? "var(--border-thin-inverted)" : ""};
  border-width: ${(props) => (props.thick ? "2px" : "")};

  ${(props) =>
    props.spacing === "small" &&
    css`
      margin-top: var(--spacing-small);
      margin-bottom: calc(var(--spacing-small) - 1px);
    `}

  ${(props) =>
    props.spacing === "medium" &&
    css`
      margin-top: var(--spacing-large);
      margin-bottom: calc(var(--spacing-large) - 1px);
    `}

  ${(props) =>
    props.spacing === "large" &&
    css`
      margin-top: var(--spacing-xx-large);
      margin-bottom: calc(var(--spacing-xx-large) - 1px);
    `}

  ${(props) =>
    props.transparent &&
    css`
      border-color: transparent;
    `}    

  ${space}
`;

const Rule = ({
  inverted,
  thick,
  className,
  spacing,
  transparent,
  ...props
}) => {
  return (
    <RuleElement
      className={className}
      inverted={inverted}
      thick={thick}
      spacing={spacing}
      transparent={transparent}
      {...props}
    />
  );
};

export default Rule;
