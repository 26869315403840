import React from "react";
import styled from "styled-components";

const CaseStudyParagraphElement = styled.p`
  max-width: 64ch;
`;

const CaseStudiesParagraph = ({ children }) => {
  return <CaseStudyParagraphElement>{children}</CaseStudyParagraphElement>;
};

export default CaseStudiesParagraph;
