import React from "react";
import styled from "styled-components";
import Heading from "../../Heading";

const CaseStudyHeading = styled(Heading)`
  text-align: center;
  width: auto;
  margin-bottom: var(--spacing-x-medium);
  box-shadow: 0 0 12px 0 #2b2b2b;
  background-image: linear-gradient(to right, #ff73df, #d865bd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  text-shadow: none;
  font-size: var(--font-size-heading-xxx-large);
  line-height: var(--line-height-reset);
  @media (min-width: 600px) {
    text-align: left;
  }
`;

const CaseStudiesHeading = ({ title }) => {
  return <CaseStudyHeading>{title}</CaseStudyHeading>;
};

export default CaseStudiesHeading;
