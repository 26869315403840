import React, { useState } from "react";
import styled, { css } from "styled-components";
import Icons from "../../../Icons";
import Badge from "../../../Badge";
import ProjectTitle from "../ProjectTitle";
import ProjectSection from "../ProjectSection";
import ProjectSectionSkills from "../ProjectSectionSkills";
import ProjectDescription from "../ProjectDescription";
import ProjectHeroImage from "../ProjectHeroImage";
import Widgets from "../../../../img/ios14-homescreen.jpg";
import AppLibrary from "../../../../img/ios14-app-library.gif";
import AppLibraryVideo from "../../../../video/iOS14-Beta-App-Lib.mp4";
import IPhoneImage from "../../../../img/iphone-device.png";
import IOSBetaPoster from "../../../../img/ios14-app-library.gif";

const BankingProjectSection = styled(ProjectSection)`
  background: #0084a9;
`;

const IOSProjectDescription = styled(ProjectDescription)`
  @media (pointer: fine) {
    margin-bottom: var(--spacing-clear);
  }
`;

const IPhone = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  pointer-events: none;
  z-index: 1;
`;

const IPhoneWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 30rem;
  height: 65rem;
  margin: 0;
  align-items: center;
`;

const Video = styled.video`
  width: 100%;
  height: auto;
  padding: 2.1rem;
  &:focus {
    outline: none;
  }
`;

const ModalButton = styled.button`
  display: none;
  @media (pointer: fine) {
    display: block;
    margin: var(--spacing-medium) auto var(--spacing-x-medium);
    background-color: rgba(0, 0, 0, 0.05);
    display: inline-block;
    text-align: center;
    color: white;
    height: auto;
    text-decoration: none;
    padding: 0px 1.5em;
    border-radius: 3.125em;
    line-height: 3;
    box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.5),
      0 -1px 0 0 rgba(255, 255, 255, 0.1);
    border-bottom: none;
    font-size: var(--font-size-text-small);
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
`;

const Modal = styled.div`
  ${(props) =>
    props.modalOpen &&
    css`
      background: #000000cc;
      top: 0;
      left: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: 100000;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        display: block;
        margin: var(--spacing-medium) auto var(--spacing-clear);
        background-color: #333333;
        display: inline-block;
        text-align: center;
        color: white;
        height: auto;
        text-decoration: none;
        padding: 0px 1.5em;
        border-radius: 3.125em;
        line-height: 3;
        box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.5),
          0 -1px 0 0 rgba(255, 255, 255, 0.1);
        border-bottom: none;
        font-size: var(--font-size-text-small);
        position: absolute;
        left: 24px;
        top: 24px;
        &:hover {
          color: #ff73df;
        }
      }
    `}
`;

function IOS14() {
  const [modalOpen, setOpen] = useState(true);
  return (
    <BankingProjectSection>
      <ProjectTitle title="iOS14 Public beta" />
      <ProjectSectionSkills>
        <Badge small title="UI Design">
          <Icons type="rgba-small" />
        </Badge>
        <Badge small title="UX Design">
          <Icons type="ux-small" />
        </Badge>
      </ProjectSectionSkills>
      <IOSProjectDescription>
        My exploration improves the layout and functionality of the home screen
        widgets and increases the efficiency of accessing the App Library. The
        hypothesis is that integrating the 'App Library' into the 'Siri search'
        would be more familiar to a user, and faster to complete the intended
        task.
      </IOSProjectDescription>
      <ModalButton onClick={() => setOpen(!modalOpen)}>Watch video</ModalButton>
      {modalOpen ? (
        ""
      ) : (
        <Modal modalOpen>
          <button onClick={() => setOpen(!modalOpen)}>Close</button>
          <IPhoneWrapper>
            <IPhone src={IPhoneImage} />
            <Video width="300" height="650" controls poster={IOSBetaPoster}>
              <source src={AppLibraryVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </Video>
          </IPhoneWrapper>
        </Modal>
      )}
      <ProjectHeroImage srcMobile1={Widgets} srcMobile2={AppLibrary} mobile />
    </BankingProjectSection>
  );
}

export default IOS14;
