import React from "react";
import styled from "styled-components";
import Icons from "../../../Icons";
import Badge from "../../../Badge";
import ProjectTitle from "../ProjectTitle";
import ProjectSection from "../ProjectSection";
import ProjectSectionSkills from "../ProjectSectionSkills";
import ProjectDescription from "../ProjectDescription";
import ProjectHeroImage from "../ProjectHeroImage";
import RoyaltyImage1 from "../../../../img/royalty-login.jpg";
import RoyaltyImage2 from "../../../../img/royalty-home.jpg";

const RoyaltyProjectSection = styled(ProjectSection)`
  background: #2b150c;
`;

function Royalty() {
  return (
    <RoyaltyProjectSection>
      <ProjectTitle title="Royalty" />
      <ProjectSectionSkills>
        <Badge small title="UI Design">
          <Icons type="rgba-small" />
        </Badge>
        <Badge small title="UX Design">
          <Icons type="ux-small" />
        </Badge>
      </ProjectSectionSkills>
      <ProjectDescription>
        iOS loyalty coffee app that makes use of iBeacon technology, so leave
        your loyalty cards at home. I contributed to this project by directing
        the user experience through the use of wireframes and creating the
        overall design aesthetic.
      </ProjectDescription>

      <ProjectHeroImage
        mobile
        srcMobile1={RoyaltyImage1}
        srcMobile2={RoyaltyImage2}
      />
    </RoyaltyProjectSection>
  );
}

export default Royalty;
