import React from "react";
import styled from "styled-components";

const ContainerElement = styled.div`
  display: block;
  width: 95%;
  width: calc(100% - 9.6rem);
  max-width: 118rem;
  padding: 2.4rem 0;
  min-width: 30rem;
  margin: 0 4.8rem;
  max-width: ${props => (props.fluid ? "100%" : "")};
  margin: ${props => (props.center ? "0 auto" : "")};
  max-width: ${props => (props.small ? "54.0rem" : "")};
  max-width: ${props => (props.medium ? "70.0rem" : "")};
  max-width: ${props => (props.large ? "96.0rem" : "")};
  max-width: ${props => (props.larger ? "118.0rem" : "")};
`;

const Container = ({
  className,
  children,
  fluid,
  center,
  small,
  medium,
  large
}) => {
  return (
    <ContainerElement
      className={className}
      fluid={fluid}
      center={center}
      small={small}
      medium={medium}
      large={large}
    >
      {children}
    </ContainerElement>
  );
};

export default Container;
