import React from "react";
import styled from "styled-components";

const CaseStudyIntroductionElement = styled.div`
  margin-bottom: var(--spacing-x-large);
  h1 {
    max-width: 28ch;
  }
  p {
    font-size: var(--font-size-heading-x-small);
    max-width: 62ch;
    &:last-child {
      margin: 0;
    }
  }
  a {
    color: white;
    border-color: rgba(255, 255, 255, 0.2);
    &:hover {
      color: #ff73df;
    }
  }
`;

const CaseStudyIntroduction = ({ children, className }) => {
  return (
    <CaseStudyIntroductionElement className={className}>
      {children}
    </CaseStudyIntroductionElement>
  );
};

export default CaseStudyIntroduction;
