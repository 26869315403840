import React from "react";
import styled from "styled-components";
import Icons from "../../../Icons";
import Badge from "../../../Badge";
import ProjectTitle from "../ProjectTitle";
import ProjectSection from "../ProjectSection";
import ProjectSectionSkills from "../ProjectSectionSkills";
import ProjectDescription from "../ProjectDescription";
import ProjectHeroImage from "../ProjectHeroImage";
import Capture1 from "../../../../img/capturex-1.jpg";
import Capture2 from "../../../../img/capturex-2.jpg";

const CaptureProjectSection = styled(ProjectSection)`
  background: #703993;
`;

function Capture() {
  return (
    <CaptureProjectSection>
      <ProjectTitle title="Capture" />
      <ProjectSectionSkills>
        <Badge small title="UI Design">
          <Icons type="rgba-small" />
        </Badge>
        <Badge small title="UX Design">
          <Icons type="ux-small" />
        </Badge>
      </ProjectSectionSkills>
      <ProjectDescription>
        The capture experience allows business owners to record and store their
        business-related expenses in a convenient and accessible digital format.
        By having this document available in the MYOB, the system enables their
        accountant to have access to all the information they need for the tax
        period.
      </ProjectDescription>
      <ProjectHeroImage srcMobile1={Capture1} srcMobile2={Capture2} mobile />
    </CaptureProjectSection>
  );
}

export default Capture;
