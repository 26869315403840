import styled from "styled-components";
import { space, layout, typography, color, border } from "styled-system";

const Quote = styled.blockquote`
  margin: var(--spacing-medium) var(--spacing-clear);
  font-style: italic;
  border-left: var(--border-thick);
  padding-left: var(--spacing-medium);
  font-size: var(--spacing-medium);
  line-height: 1.5;
  ${space}
  ${layout}
  ${typography}
  ${color}
  ${border}
`;

export default Quote;
