import React from "react";
import styled from "styled-components";

const MobileElementWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 40%);
  grid-column-gap: 1.5rem;
  justify-content: center;
  margin-bottom: 0;
`;

const MobileIndividualElement = styled.div`
  background: #000000;
  border-radius: 2.2rem;
  @media (min-width: 360px) {
    border-radius: 2.8rem;
  }
  @media (min-width: 400px) {
    border-radius: 3.4rem;
  }
  @media (min-width: 500px) {
    border-radius: 4.2rem;
  }
`;

const MobileIndividualImage = styled.img`
  width: 100%;
  height: auto;
  padding: 7%;
  display: block;
  min-height: 17.5rem;
  @media (min-width: 500px) {
    padding: 1.6rem;
    min-height: 32.4rem;
  }
  @media (min-width: 768px) {
    min-height: 47rem;
  }
  @media (min-width: 850px) {
    min-height: 54.3rem;
  }
`;

const IPhoneXSTemplate = styled.p`
  font-size: var(--font-size-text-tiny);
  grid-column: 1/3;
  margin-bottom: var(--spacing-large);
  opacity: 0.7;
  a {
    margin-left: var(--spacing-x-tiny);
    color: white;
  }
`;

const DesktopElement = styled.div`
  background: #000000;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  margin-bottom: 0;
`;

const DesktopIndividualImage = styled.img`
  width: 100%;
  display: block;
  height: auto;
  padding: 5.106382978723%;
  padding-bottom: 0px;
  min-height: 14.41667rem;
  @media (min-width: 500px) {
    min-height: 20.84615rem;
  }
  @media (min-width: 768px) {
    min-height: 21rem;
  }
  @media (min-width: 850px) {
    min-height: 25.25rem;
  }
`;

const PictureElement = styled.div`
  background: #000000;
  border-radius: 1em;
  &:last-child {
    margin-bottom: 0;
  }
`;

const PictureIndividualImage = styled.img`
  width: 100%;
  display: block;
  height: auto;
  padding: 5.106382978723%;
  min-height: 14.41667rem;
  @media (min-width: 500px) {
    min-height: 20.84615rem;
  }
  @media (min-width: 768px) {
    min-height: 21rem;
  }
  @media (min-width: 850px) {
    min-height: 25.25rem;
  }
`;

const WatchRow = styled.div`
  margin: var(--spacing-clear);
  @media (min-width: 500px) {
    display: flex;
    justify-content: space-around;
  }
`;

const WatchAndroid = styled.div`
  position: relative;
  padding: 5.4rem var(--spacing-clear);
  margin-bottom: var(--spacing-large);
  &:last-child {
    margin-bottom: var(--spacing-clear);
  }
  @media (min-width: 500px) {
    &:last-child {
      margin-bottom: var(--spacing-large);
    }
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    background: #000000;
    margin-left: auto;
    margin-right: auto;
    left: var(--spacing-clear);
    right: var(--spacing-clear);
    border-radius: 1.8rem;
  }
  &:before {
    height: 35.2rem;
    width: 13.2rem;
    top: calc(50% - (35.2rem / 2));
  }
  &:after {
    width: 17rem;
    height: 27rem;
    top: calc(50% - (27rem / 2));
  }
`;

const WatchAndroidWatchFace = styled.div`
  margin: 0 auto;
  width: 24.4rem;
  height: 24.4rem;
  border-radius: 24.4rem;
  padding: var(--spacing-small);
  background: #000000;
  z-index: 1;
  position: relative;
  &:before {
    content: "";
    background: #000000;
    width: 14rem;
    height: 3.6rem;
    display: block;
    position: absolute;
    bottom: 0;
    left: calc(50% - 7rem);
  }
`;

const WatchOS = styled.div`
  position: relative;
  margin: var(--spacing-clear) auto;
  padding: 5.4rem var(--spacing-clear);
  width: 20.8rem;
  margin-top: var(--spacing-small);
  margin-bottom: var(--spacing-large);
  &:last-child {
    margin-bottom: var(--spacing-clear);
  }
  @media (min-width: 500px) {
    &:last-child {
      margin-bottom: var(--spacing-large);
    }
  }
  &:before {
    content: "";
    position: absolute;
    background: #000000;
    margin-left: auto;
    margin-right: auto;
    left: var(--spacing-clear);
    right: var(--spacing-clear);
    border-radius: 1.8rem;
  }
  &:before {
    height: 35.2rem;
    width: 13.2rem;
    top: calc(50% - (35.2rem / 2));
  }
`;

const WatchOSWatchFace = styled.div`
  margin: 0 auto;
  padding: 2.8rem;
  width: auto;
  border-radius: 3.2rem;
  background: black;
  box-shadow: inset 0 0 0 10px #000000;
  z-index: 1;
  position: relative;
`;

const WatchImage = styled.img`
  width: 100%;
  display: block;
  height: auto;
`;

const ProjectHeroImage = ({
  mobile,
  desktop,
  picture,
  watchAndroid,
  watchOS,
  src,
  srcMobile1,
  srcMobile2,
  srcWatch1,
  srcWatch2,
  alt,
}) => (
  <>
    {mobile && (
      <MobileElementWrapper>
        <MobileIndividualElement>
          <MobileIndividualImage
            src={srcMobile1}
            width="280"
            height="489"
            alt={alt}
          />
        </MobileIndividualElement>
        <MobileIndividualElement>
          <MobileIndividualImage
            src={srcMobile2}
            width="280"
            height="489"
            alt={alt}
          />
        </MobileIndividualElement>
        <IPhoneXSTemplate>
          Michael Trilford's Portfolio -
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.figma.com/file/mlixzf10kNgf4e1sHJ0v2P/iPhoneXS-Display?node-id=0%3A1"
          >
            Figma iPhone XS template
          </a>
        </IPhoneXSTemplate>
      </MobileElementWrapper>
    )}
    {desktop && (
      <DesktopElement>
        <DesktopIndividualImage src={src} alt={alt} />
      </DesktopElement>
    )}
    {picture && (
      <>
        <PictureElement>
          <PictureIndividualImage src={src} alt={alt} />
        </PictureElement>
      </>
    )}
    {watchAndroid && (
      <WatchRow>
        <WatchAndroid>
          <WatchAndroidWatchFace>
            <WatchImage src={srcWatch1} width="215" height="215" alt={alt} />
          </WatchAndroidWatchFace>
        </WatchAndroid>
        <WatchAndroid>
          <WatchAndroidWatchFace>
            <WatchImage src={srcWatch2} width="215" height="215" alt={alt} />
          </WatchAndroidWatchFace>
        </WatchAndroid>
      </WatchRow>
    )}
    {watchOS && (
      <WatchRow>
        <WatchOS>
          <WatchOSWatchFace>
            <WatchImage src={srcWatch1} width="215" height="215" alt={alt} />
          </WatchOSWatchFace>
        </WatchOS>

        <WatchOS>
          <WatchOSWatchFace>
            <WatchImage src={srcWatch2} width="215" height="215" alt={alt} />
          </WatchOSWatchFace>
        </WatchOS>
      </WatchRow>
    )}
  </>
);

export default ProjectHeroImage;
