import { createGlobalStyle } from "styled-components";
import MikesFontWOFF2 from "../fonts/mikes-website.woff2";
import MikesFontWOFF from "../fonts/mikes-website.woff";
import MikesFontTTF from "../fonts/mikes-website.ttf";

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'UniversLTPro-65Bold';
    src:  url(${MikesFontWOFF2}) format('woff2'), 
          url(${MikesFontWOFF}) format('woff'), 
          url(${MikesFontTTF}) format('truetype');
  }

  body {
    font-family: Verdana, sans-serif;
  }

  #root {
    margin-bottom: 0;
  }

  \:root {
    text-align: center;
    color: white;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15);
  }

  \:root,
  article, 
  section, 
  header, 
  footer {
    background: #2b2b2b;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    font-family: "UniversLTPro-65Bold", Verdana, sans-serif;
  }

`;

export default GlobalStyle;
