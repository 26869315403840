import React from "react";
import styled from "styled-components";
import { grid, space } from "styled-system";

const ColumnLayoutWrapper = styled.div`
  margin-bottom: var(--spacing-xx-large);
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  @media (min-width: 1024px) {
    display: grid;
    grid-gap: var(--spacing-large);
  }
  p,
  ol,
  ul {
    max-width: 64ch;
    &:last-child,
    li:last-child {
      margin-bottom: var(--spacing-clear);
    }
  }
  p,
  ol,
  ul {
    max-width: 64ch;
  }
  ${grid}
  ${space}
`;

const ColumnLayout = ({ children, ...props }) => {
  return <ColumnLayoutWrapper {...props}>{children}</ColumnLayoutWrapper>;
};

export default ColumnLayout;
