import React from "react";
import Image from "../../Image";
import CaseStudyHeading from "./CaseStudiesHeading";
import DesignSprintMindMap from "../../../img/casestudy/design-sprint/mind-map.jpg";
import DesignSprintLightningTalks from "../../../img/casestudy/design-sprint/lightning-talks.jpg";
import DesignSprintExperienceMap from "../../../img/casestudy/design-sprint/experience-map.jpg";
import DesignSprintExperienceMapExercise from "../../../img/casestudy/design-sprint/experience-map-exercise.jpg";
import Rule from "../../Rule";
import Heading from "../../Heading";
import ColumnHeading from "./ColumnHeading";
import CaseStudyProfile from "./CaseStudiesProfile";
import CaseStudiesIntroduction from "./CaseStudiesIntroduction";
import ColumnLayout from "./ColumnLayout";
import DesignSprintPrototyping from "../../../img/casestudy/design-sprint/design-sprint-prototyping.jpg";
import DesignSprintUserTesting from "../../../img/casestudy/design-sprint/design-sprint-user-testing.jpg";
import CaseStudiesCard from "./CaseStudiesCard";

const CaseStudiesDesignSprint = () => {
  return (
    <>
      <CaseStudyHeading title="Design sprints" />

      <CaseStudyProfile>
        <p>
          Early on in my career as a designer, I was fortunate enough to move
          from the Waterfall process to an Agile way of working. Instead of
          working separately, the agile process enabled a lot more collaboration
          much earlier in the process, which allowed for fresh ideas from
          different disciplines to be suggested.
        </p>
        <p>
          With the popularity and collaborative nature of a Design Sprint, it
          has helped eliminate wasteful upfront design and replace it with a
          collaborative process where we generate, discuss, test and discard
          ideas until we know we are solving the customer problem successfully.
        </p>
      </CaseStudyProfile>

      <CaseStudiesCard>
        <CaseStudiesIntroduction>
          <Heading>Rural accounting</Heading>
          <p>
            In this design sprint, the cross-collaborative group looked to solve
            known and unknown problems within the Accounting rural space.
          </p>
          <Rule spacing="medium" inverted />
        </CaseStudiesIntroduction>

        <ColumnLayout gridTemplateColumns={{ lg: "22rem 1fr" }}>
          <ColumnHeading
            titleNumber="1."
            title="Discovery"
            paragraph="Before kicking off the design sprint, I undertook discovery 
            sessions with Accountants within the Rural domain. The Rural 
            area was very new to me, so I researched the space by talking 
            to internal resources at MYOB, which helped shape a mindmap of 
            questions that helped me formulate the basis of the interview script."
          />
          <Image dark src={DesignSprintMindMap} alt="Rural mind map" />
        </ColumnLayout>

        <ColumnLayout gridTemplateColumns={{ lg: "22rem 1fr" }}>
          <ColumnHeading
            titleNumber="2."
            title="Lightning talks"
            paragraph="
            The Product Manager and I each gave a 5-minute talk to the group involved in the design sprint. My role was to present the customer problems, which I referred to the findings from the interviews carried out with the rural accountants. Supporting the talks, we also used storyboarding to tell the more complex experiences from the discovery sessions.
            "
          />
          <Image dark src={DesignSprintLightningTalks} alt="Lightning talks" />
        </ColumnLayout>

        <ColumnLayout gridTemplateColumns={{ lg: "22rem 1fr" }}>
          <ColumnHeading
            titleNumber="3."
            title="Journey map"
            paragraph="From the lightning talks, this enabled the cross-collaborative group 
            to map out the customer journey from the initial user discovery. Once a 
            journey was mapped out, this provided the group with an informative 
            view of the customer's experience. This new perspective allowed the group 
            to identify problem areas within the journey and then eliminate them 
            from the journey."
          />
          <div>
            <Image
              dark
              src={DesignSprintExperienceMapExercise}
              alt="Journey map workshop"
              caption="Journey map workshop"
            />
            <p>
              I transformed the group activity of prototyping the customer
              journey into a high-fidelity version so that we could reference it
              later with stakeholders or new additions to the team, which would
              help with onboarding them to the domain.
            </p>
            <Image
              dark
              src={DesignSprintExperienceMap}
              alt="Journey map: From lo-fi to hi-fi"
              caption="Journey map: From lo-fi to hi-fi"
            />
          </div>
        </ColumnLayout>

        <ColumnLayout gridTemplateColumns={{ lg: "22rem 1fr" }}>
          <ColumnHeading
            titleNumber="4."
            title="Prototyping"
            paragraph="Once we identified opportunities within the journey map, the group worked together to formulate hypothesises, which the group could start to ideate using the crazy eight exercise. The task consisted of dividing an A3 sheet of paper into eight sections, and then the task of generating ideas would commence. Once completed, each person would share their ideas, and another round of conceptualising would begin, where the presented approaches can be used for inspiration. This exercise is a great process to gain alignment and come up with new ideas.
            "
          />
          <div>
            <Image
              dark
              src={DesignSprintPrototyping}
              alt="Design Sprint Prototyping"
              caption="Design Sprint Prototyping"
            />
          </div>
        </ColumnLayout>

        <ColumnLayout gridTemplateColumns={{ lg: "22rem 1fr" }}>
          <ColumnHeading
            titleNumber="5."
            title="User testing"
            paragraph="After completion of the lo-fi prototype, the cross-collaborative team was able to create a hi-fi prototype rather swiftly. While the prototype was being prepared, I created a script for the user test and recruited users for the validation exercise to prove or disprove the hypothesis. When the user testing sessions were underway, the cross-collaborative group attended the session and recorded notes, which I then grouped into relevant sections to extract the insights from the user test so that we could analyse the success or failure of the prototype. 
            "
          />
          <div>
            <Image
              dark
              src={DesignSprintUserTesting}
              alt="Design Sprint User Testing"
              caption="Design Sprint User Testing"
            />
          </div>
        </ColumnLayout>
      </CaseStudiesCard>
    </>
  );
};

export default CaseStudiesDesignSprint;
