import React, { useState } from "react";
import styled, { css } from "styled-components";
import Icons from "../../../Icons";
import Badge from "../../../Badge";
import ProjectTitle from "../ProjectTitle";
import ProjectSection from "../ProjectSection";
import ProjectSectionSkills from "../ProjectSectionSkills";
import ProjectDescription from "../ProjectDescription";
import ProjectHeroImage from "../ProjectHeroImage";
import Src1 from "../../../../img/myob-invoicing-insights.jpg";
import Src2 from "../../../../img/myob-invoicing-quote.jpg";

const InvoicingProjectSection = styled(ProjectSection)`
  background: #703993;
`;

const InvoicingProjectDescription = styled(ProjectDescription)`
  @media (pointer: fine) {
    margin-bottom: var(--spacing-clear);
  }
`;

const ModalButton = styled.button`
  display: none;
  @media (pointer: fine) {
    display: block;
    margin: var(--spacing-medium) auto var(--spacing-x-medium);
    background-color: rgba(0, 0, 0, 0.05);
    display: inline-block;
    text-align: center;
    color: white;
    height: auto;
    text-decoration: none;
    padding: 0px 1.5em;
    border-radius: 3.125em;
    line-height: 3;
    box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.5),
      0 -1px 0 0 rgba(255, 255, 255, 0.1);
    border-bottom: none;
    font-size: var(--font-size-text-small);
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
`;

const Modal = styled.div`
  ${(props) =>
    props.modalOpen &&
    css`
      background: #000000cc;
      top: 0;
      left: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: 100000;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        display: block;
        margin: var(--spacing-medium) auto var(--spacing-clear);
        background-color: #333333;
        display: inline-block;
        text-align: center;
        color: white;
        height: auto;
        text-decoration: none;
        padding: 0px 1.5em;
        border-radius: 3.125em;
        line-height: 3;
        box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.5),
          0 -1px 0 0 rgba(255, 255, 255, 0.1);
        border-bottom: none;
        font-size: var(--font-size-text-small);
        position: absolute;
        left: 24px;
        top: 24px;
        &:hover {
          color: #ff73df;
        }
      }
    `}
`;

const IFrame = styled.iframe`
  border: none;
`;

function MYOBInvoices() {
  const [modalOpen, setOpen] = useState(true);

  return (
    <InvoicingProjectSection>
      <ProjectTitle title="MYOB Invoicing" />
      <ProjectSectionSkills>
        <Badge small title="UI Design">
          <Icons type="rgba-small" />
        </Badge>
        <Badge small title="UX Design">
          <Icons type="ux-small" />
        </Badge>
      </ProjectSectionSkills>
      <InvoicingProjectDescription>
        Research has shown that quotes would be a useful feature for MYOB
        Invoicing to introduce. With the task of adding quotes to the current
        mobile application, there was an opportunity to review the workflow
        across Invoices also, so the work completed in quotes would need to be a
        holistic vision for the future experience of the MYOB Invoicing
        application.
      </InvoicingProjectDescription>
      <ModalButton onClick={() => setOpen(!modalOpen)}>
        View Invision prototype
      </ModalButton>
      {modalOpen ? (
        ""
      ) : (
        <Modal modalOpen>
          <button onClick={() => setOpen(!modalOpen)}>Close</button>
          <IFrame
            width="442"
            height="935"
            src="//invis.io/2NWBCD857DX"
            frameborder="0"
            allowfullscreen
            onload="this.style.visibility = 'visible';"
          ></IFrame>
        </Modal>
      )}

      <ProjectHeroImage srcMobile1={Src1} srcMobile2={Src2} mobile />
    </InvoicingProjectSection>
  );
}

export default MYOBInvoices;
