const baseColors = {
  darkGrey: "var(--dark-grey-palette)",
  gloomGrey: "var(--gloom-grey-palette)",
  grey: "var(--grey-palette)",
  lightGrey: "var(--light-grey-palette)",
  black: "var(--black-palette)",
  white: "var(--white-palette)",
  whiteTransparent: "var(--white-palette-transparent-50)",
  red: "var(--red-palette)",
};

const breakpoints = ["500px", "768px", "1024px", "1200px"];

// aliases
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export default {
  breakpoints,
  colors: {
    darkGrey: baseColors.darkGrey,
    gloomGrey: baseColors.gloomGrey,
    grey: baseColors.grey,
    lightGrey: baseColors.lightGrey,
    black: baseColors.black,
    white: baseColors.white,
    whiteTransparent: baseColors.whiteTransparent,
    red: baseColors.red,
  },
  lineHeights: {
    text: " var(--line-height-text)",
    reset: "var(--line-height-reset)",
    largest: "var(--line-height-heading-largest)",
    larger: "var(--line-height-heading-larger)", // H1
    large: "var(--line-height-heading-large)", // H2
    medium: "var(--line-height-heading-medium)", // H3
    small: "var(--line-height-heading-small)", // H4
    smaller: "var(--line-height-heading-smaller)", // H5
    smallest: "var(--line-height-heading-smallest)", // H6
  },
  fontSizes: {
    largest: "var(--font-size-heading-xx-large)",
    larger: "var(--font-size-heading-x-large)", // H1
    large: "var(--font-size-heading-large)", // H2
    medium: "var(--font-size-heading-medium)", // H3
    small: "var(--font-size-heading-small)", // H4
    smaller: "var(--font-size-heading-x-small)", // H5
    smallest: "var(--font-size-heading-tiny)", // H6
  },
  fontWeights: {
    normal: "normal",
    bold: "bold",
  },
  fonts: {
    display: "'UniversLTPro-65Bold', 'Verdana', 'sans-serif'",
  },
  space: {
    largest: "var(--spacing-x-large)",
    larger: "var(--spacing-large)",
    large: "var(--spacing-x-medium)",
    medium: "var(--spacing-medium)",
    small: "var(--spacing-small)",
    smaller: "var(--spacing-x-small)",
    smallest: "var(--spacing-tiny)",
    clear: "var(--spacing-clear)",
  },
  boxShadows: {
    buttonSecondary: `inset 0 0 0 1px ${baseColors.darkGrey}`,
  },
  buttons: {
    primary: {
      color: "white",
      bg: "darkGrey",
    },
    secondary: {
      color: "darkGrey",
      bg: "white",
      // Extending style with object-based CSS syntax
      boxShadow: "inset 0 0 0 1px var(--dark-grey-palette)",
    },
    warning: {
      // STYLE-SYSTEM INTERNAL USAGE
      color: "white",
      bg: "red",
    },
  },
};
