import React from "react";
import styled from "styled-components";
import Rule from "../../../Rule";
import Image from "../../../Image";
import Links from "../../../Links";
import Heading from "../../../Heading";
import CaseStudiesIntroduction from "../CaseStudiesIntroduction";
import ColumnLayout from "../ColumnLayout";
import ColumnHeading from "../ColumnHeading";
import CaseStudiesCard from "../CaseStudiesCard";
import DesignSystemsProcessDiagram from "../../../../img/casestudy/design-systems/design-system-process-diagram.jpg";
import DesignSystemsDistillInsights from "../../../../img/casestudy/design-systems/design-system-distill-insights.jpg";
import DesignSystemsRoadmapPlan from "../../../../img/casestudy/design-systems/design-system-roadmap-plan.png";
import OutcomeBasedRoadmapImage from "../../../../img/casestudy/design-systems/design-system-outcome-based-roadmap.gif";
import DoThinkFeelTemplate from "../../../../files/do-think-feel.zip";

const OKRExampleHeading = styled.h5``;

const OKRExample = styled.div`
  margin-bottom: var(--spacing-clear);
  ${OKRExampleHeading} {
    margin-bottom: 1.6rem;
  }
`;

const Insight = styled.span`
  color: #f788de;
  font-weight: bold;
`;

const Do = styled.span`
  color: #88c1f7;
  font-weight: bold;
`;

const Think = styled.span`
  color: #9788f8;
  font-weight: bold;
`;

const Feel = styled.span`
  color: #84bd50;
  font-weight: bold;
`;

const DesigningTheRoadmap = (
  <CaseStudiesCard id="designtheroadmap">
    <CaseStudiesIntroduction>
      <Heading>Designing the roadmap</Heading>
      <p>
        With the discovery and roadmap creation tasks, I used a few methods that
        range from James Kalbach's{" "}
        <Links
          target="_blank"
          rel="noopener noreferrer"
          href="http://shop.oreilly.com/product/0636920038870.do"
        >
          Mapping Experiences
        </Links>{" "}
        and Jeff Gothelf's{" "}
        <Links
          target="_blank"
          rel="noopener noreferrer"
          href="https://medium.com/@jboogie/what-does-an-agile-product-roadmap-look-like-cf0dbe5be4ef"
        >
          Agile product roadmap
        </Links>{" "}
        blog post and{" "}
        <Links
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.senseandrespondpress.com/managing-outcomes"
        >
          Outcomes over Output
        </Links>{" "}
        publication.
      </p>
      <Rule spacing="medium" inverted />
    </CaseStudiesIntroduction>
    <ColumnLayout gridTemplateColumns={{ lg: "22rem 1fr 1fr" }}>
      <ColumnHeading
        titleNumber="1."
        title="Discovery"
        paragraph="
      The most important part of designing the roadmap was to visit the MYOB offices and run workshops to understand the pain-points within the Front-end space at MYOB."
      />
      <div>
        <Image
          dark
          src={DesignSystemsProcessDiagram}
          alt="Design system process diagram"
        />
        <Heading variant="smaller" mb="small" mt="clear" color="#ff73df">
          Process diagrams
        </Heading>
        <p>
          In our workshops across MYOB, we asked the Front-end participants to
          map out the end-to-end journey when building experiences at MYOB. Once
          the journeys were mapped out, we asked a representative from each
          group to talk through the process. From the qualitative feedback
          collected, we were then able to begin the next phase of designing our
          roadmap.
        </p>
        <Heading variant="smallest">The format of the workshops:</Heading>
        <ul>
          <li>
            In groups, map out the current end-to-end journey when building
            products at MYOB.
          </li>
          <li>Highlight pain points within the process</li>
          <li>List some ideas on how to improve the areas of frustration</li>
        </ul>
      </div>
      <div>
        <Heading variant="smaller" mb="small" mt="clear" color="#ff73df">
          Distilling insights
        </Heading>
        <p>
          The notes, audio and process maps were analysed for clues. Each clue
          captured consisted of 1 customer point per post-it note, and as many
          as the collected data can provide. To simplify the data analysis, I
          arranged the clues horizontally into
          <Do> Do</Do>,<Feel> Feel</Feel> & <Think>Think</Think> lanes.
        </p>
        <Image
          dark
          src={DesignSystemsDistillInsights}
          alt="Design system distilling insights"
        />
        <p>
          The next task was to distil the qualitative data into actionable
          insights. I began organising the clues into vertical lanes that
          represented common themes. This arrangement allowed me to write a
          single sentence that captured the essence of each topic, which is then
          categorised into the
          <Insight> Insight</Insight> column.
        </p>
        <Rule inverted />
        <p>
          <Links href={DoThinkFeelTemplate} download>
            Download the "Do, Feel & Think" template
          </Links>
        </p>
      </div>
    </ColumnLayout>
    <ColumnLayout gridTemplateColumns={{ lg: "22rem 1fr" }}>
      <ColumnHeading
        titleNumber="2."
        title="Prototype"
        paragraph="
        After distilling the insights, we had a clear picture of the macro problems within the Design system. With the higher-level issues, it enabled the ability to start mapping to existing backlog items and fleshing out the order of the backlog and roadmap items."
      />
      <div>
        <Image
          dark
          src={DesignSystemsRoadmapPlan}
          alt="Prototyping the roadmap"
        />
      </div>
    </ColumnLayout>
    <ColumnLayout gridTemplateColumns={{ lg: "22rem 1fr" }}>
      <ColumnHeading
        titleNumber="3."
        title="Agile roadmap"
        paragraph="
        With a clear priority of the backlog and order of the roadmap items, it was 
        now time to align the defined milestones into an OKR format. Our stakeholders 
        and interested parties will know at a glance, what the objective is, and how we're 
        tracking to complete the roadmap item(s)."
      />
      <div>
        <Image
          dark
          src={OutcomeBasedRoadmapImage}
          alt="Outcome-based roadmap"
          caption="Outcome-based roadmap"
          spacing="large"
        />

        <OKRExample>
          <OKRExampleHeading>Example OKR</OKRExampleHeading>
          <p>
            The main objective is to automate the Product Design UIKit to be a
            1:1 match of what is available in the Feelix Design System.
          </p>
          <Heading variant="smallest">Key Result(s)</Heading>
          <ul>
            <li>100% of components available in the chosen design tool</li>
            <li>
              The Design feedback of the provided solution is overwhelmingly
              positive (&gt; 60%) that outweighs the negative feedback.
            </li>
            <li>Time spent by Visual Design updating the UI Kit is 0hrs.</li>
            <li>
              A Developer can 100% correctly implement a Feelix designed
              experience without design involvement.
            </li>
            <li>
              The Developer feedback of the provided solution is overwhelmingly
              positive (&gt; 60%) that outweighs the negative feedback.
            </li>
          </ul>
        </OKRExample>
      </div>
    </ColumnLayout>
  </CaseStudiesCard>
);

export default DesigningTheRoadmap;
