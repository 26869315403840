import React from "react";
import styled from "styled-components";
import Icons from "../../../Icons";
import Badge from "../../../Badge";
import Links from "../../../Links";
import ProjectTitle from "../ProjectTitle";
import ProjectSection from "../ProjectSection";
import ProjectSectionSkills from "../ProjectSectionSkills";
import ProjectDescription from "../ProjectDescription";
import ProjectHeroImage from "../ProjectHeroImage";
import MuiImage from "../../../../img/mui.jpg";

const MuiProjectSection = styled(ProjectSection)`
  background: #2b2b2b;
`;

function Mui() {
  return (
    <MuiProjectSection>
      <ProjectTitle title="Mui Pattern Library" />
      <ProjectSectionSkills>
        <Badge small title="UI Design">
          <Icons type="rgba-small" />
        </Badge>
        <Badge small title="HTML">
          <Icons type="html-small" />
        </Badge>
        <Badge small title="CSS">
          <Icons type="css-small" />
        </Badge>
        <Badge tooltip="large" small title="Web Components">
          <Icons type="web-components-small" />
        </Badge>
        <Badge small title="UX Design">
          <Icons type="ux-small" />
        </Badge>
      </ProjectSectionSkills>
      <ProjectDescription>
        <Links href="https://michaeltrilford.github.io/" target="_blank">
          This pattern library
        </Links>{" "}
         is a consolidation of patterns created on my codepen.io/trilm. It isn’t
        a framework, but a collection of base styles & components to assist with
        crafting prototypes or design.
      </ProjectDescription>

      <ProjectHeroImage src={MuiImage} desktop />
    </MuiProjectSection>
  );
}

export default Mui;
