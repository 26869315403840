import React from "react";
import styled from "styled-components";

const ProjectDescriptionElement = styled.p`
  margin-bottom: var(--spacing-large);
  font-size: var(--font-size-text-small);

  @media (min-width: 768px) {
    padding: var(--spacing-clear) var(--spacing-medium);
  }
`;

const ProjectDescription = ({ className, children }) => {
  return (
    <ProjectDescriptionElement className={className}>
      {children}
    </ProjectDescriptionElement>
  );
};

export default ProjectDescription;
