import React, { useState } from "react";
import styled, { css } from "styled-components";
import Icons from "../../../Icons";
import Badge from "../../../Badge";
import ProjectTitle from "../ProjectTitle";
import ProjectSection from "../ProjectSection";
import ProjectSectionSkills from "../ProjectSectionSkills";
import ProjectDescription from "../ProjectDescription";
import ProjectHeroImage from "../ProjectHeroImage";
import OnTheWristAndroidLocked from "../../../../img/OnTheWrist-Android-Locked.png";
import OnTheWristAndroid from "../../../../img/OnTheWrist-Android.png";
import OnTheWristOSLocked from "../../../../img/OnTheWrist-OS-Locked.jpg";
import OnTheWristOS from "../../../../img/OnTheWrist-OS.jpg";
import OnTheWristVideo from "../../../../video/onTheWrist.mp4";

const AndroidProjectSection = styled(ProjectSection)`
  background: #6e04bb;
  padding-bottom: var(--spacing-large);
  @media (min-width: 500px) {
    padding-bottom: var(--spacing-clear);
  }
`;

const OnTheWristProjectDescription = styled(ProjectDescription)`
  @media (pointer: fine) {
    margin-bottom: var(--spacing-clear);
  }
`;

const ModalButton = styled.button`
  display: none;
  @media (pointer: fine) {
    display: block;
    margin: var(--spacing-medium) auto var(--spacing-x-medium);
    background-color: rgba(0, 0, 0, 0.05);
    display: inline-block;
    text-align: center;
    color: white;
    height: auto;
    text-decoration: none;
    padding: 0px 1.5em;
    border-radius: 3.125em;
    line-height: 3;
    box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.5),
      0 -1px 0 0 rgba(255, 255, 255, 0.1);
    border-bottom: none;
    font-size: var(--font-size-text-small);
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
`;

const IOSProjectSection = styled(ProjectSection)`
  background: #ad0055;
  box-shadow: none;
  padding-top: var(--spacing-large);
  padding-bottom: var(--spacing-large);
  @media (min-width: 500px) {
    padding-top: var(--spacing-clear);
    padding-bottom: var(--spacing-clear);
  }
`;

const OnTheWristWrapper = styled.article`
  margin: 0;
`;

const Video = styled.video`
  width: 30rem;
  height: auto;
  @media (min-width: 320px) {
    width: 32rem;
  }
  @media (min-width: 400px) {
    width: 52rem;
  }
  @media (min-width: 500px) {
    width: 66rem;
  }
  @media (min-width: 600px) {
    width: 74rem;
  }
  @media (min-width: 768px) {
    width: 70rem;
  }
`;

const Modal = styled.div`
  ${(props) =>
    props.modalOpen &&
    css`
      background: #000000cc;
      top: 0;
      left: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: 100000;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        display: block;
        margin: var(--spacing-medium) auto var(--spacing-clear);
        background-color: #333333;
        display: inline-block;
        text-align: center;
        color: white;
        height: auto;
        text-decoration: none;
        padding: 0px 1.5em;
        border-radius: 3.125em;
        line-height: 3;
        box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.5),
          0 -1px 0 0 rgba(255, 255, 255, 0.1);
        border-bottom: none;
        font-size: var(--font-size-text-small);
        position: absolute;
        left: 24px;
        top: 24px;
        &:hover {
          color: #ff73df;
        }
      }
    `}
`;

const Plus = styled.div`
  position: absolute;
  margin: 0;
  background: #6e04bb;
  left: calc(50% - 4.8rem);
  width: 9.6rem;
  height: 9.6rem;
  border-radius: 9.6rem;
  color: white;
  line-height: 9.1rem;
  font-size: 4.8rem;
  top: calc(0% - 4.8rem);
  font-family: "Univers Pro 65 Bold", Verdana, sans-serif;
  text-shadow: none;
`;

function OnTheWrist() {
  const [modalOpen, setOpen] = useState(true);
  return (
    <OnTheWristWrapper>
      <AndroidProjectSection>
        <ProjectTitle title="On The Wrist" />
        <ProjectSectionSkills>
          <Badge small title="UI Design">
            <Icons type="rgba-small" />
          </Badge>
          <Badge small title="UX Design">
            <Icons type="ux-small" />
          </Badge>
        </ProjectSectionSkills>
        <OnTheWristProjectDescription>
          Receive timely reminders of incoming & outstanding payments, which
          allows businesses to keep an eye on their business health, without the
          overhead of being tied to their desk. This ease of mind enables the
          business owners to focus on more critical areas, which is running and
          ensuring their business is thriving.
        </OnTheWristProjectDescription>
        <ModalButton onClick={() => setOpen(!modalOpen)}>
          Watch video
        </ModalButton>
        {modalOpen ? (
          ""
        ) : (
          <Modal modalOpen>
            <button onClick={() => setOpen(!modalOpen)}>Close</button>
            <Video width="320" height="240" controls>
              <source src={OnTheWristVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </Video>
          </Modal>
        )}
        <ProjectHeroImage
          srcWatch1={OnTheWristAndroidLocked}
          srcWatch2={OnTheWristAndroid}
          watchAndroid
        />
      </AndroidProjectSection>
      <IOSProjectSection>
        <Plus>+</Plus>
        <ProjectHeroImage
          srcWatch1={OnTheWristOSLocked}
          srcWatch2={OnTheWristOS}
          watchOS
        />
      </IOSProjectSection>
    </OnTheWristWrapper>
  );
}

export default OnTheWrist;
