import React from "react";
import Image from "../../Image";
import CaseStudyHeading from "./CaseStudiesHeading";
import Rule from "../../Rule";
import Heading from "../../Heading";
// import CaseStudiesParagraph from "./CaseStudiesParagraph";
import CaseStudiesIntroduction from "./CaseStudiesIntroduction";
import CaseStudiesCard from "./CaseStudiesCard";
// import RuralEOFY from "../../../img/casestudy/storytelling/rural-eofy.jpg";
import RuralGSTPeriod from "../../../img/casestudy/storytelling/rural-gst-period.jpg";
import RuralOperationalAdvisory from "../../../img/casestudy/storytelling/rural-operational-advisory.jpg";
import Box from "../../Box";
import Stack from "../../Stack";

const CaseStudiesStoryboard = () => {
  return (
    <>
      <CaseStudyHeading title="Story telling" />

      <CaseStudiesCard>
        <CaseStudiesIntroduction>
          <Heading>Rural accounting</Heading>
          <p>
            A picture is worth a thousand words. In this case, it is no
            different. Storyboarding is a tool that helps to translate captured
            verbatim into a sharable and engaging format.
          </p>
          <p>
            During the design sprint for Rural accounting, I used storyboarding
            techniques to assist in communicating the individual experiences
            identified in the user discovery sessions.
          </p>
          <Rule spacing="medium" inverted />
        </CaseStudiesIntroduction>
        {/* <Heading variant="medium">Rural EOFY</Heading>
        <CaseStudiesParagraph>
          During the creation of this storyboard, I referred to my experience of
          architecting design systems, so I looked to design reusable assets, so
          I could easily create the stories portrayed with the least amount of
          rework or effort.
        </CaseStudiesParagraph>
        <Image dark src={RuralEOFY} alt="Rural storyboard" />
        <Rule spacing="medium" inverted /> */}
        <Stack disableMargin spacing="48px">
          <Box margin="0">
            <Heading variant="medium">Rural GST period</Heading>
            <Image dark src={RuralGSTPeriod} alt="Rural GST period" />
          </Box>
          <Box margin="0">
            <Heading variant="medium">Rural operational advisory</Heading>
            <Image
              dark
              src={RuralOperationalAdvisory}
              alt="Rural operational advisory"
            />
          </Box>
        </Stack>
      </CaseStudiesCard>
    </>
  );
};

export default CaseStudiesStoryboard;
