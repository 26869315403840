import React from "react";
import styled from "styled-components";
import Links from "../../Links";
import Image from "../../Image";
import Rule from "../../Rule";
import Heading from "../../Heading";
import CaseStudyProfile from "./CaseStudiesProfile";
import CaseStudyParagraph from "./CaseStudiesParagraph";
import CaseStudyHeading from "./CaseStudiesHeading";
import ColumnLayout from "./ColumnLayout";
import TechnologyUsedDesignTokens from "../../../img/casestudy/technology-used/technology-used-design-tokens.jpg";
import CaseStudiesCard from "./CaseStudiesCard";

const CodeElementBlock = styled.section`
  display: block;
  width: 100%;
  padding: var(--spacing-small);
  background: var(--black-palette);
  border-radius: var(--radius-card);
`;

const CodeElement = styled.code`
  margin-bottom: var(--spacing-small);
  display: block;
  &:last-child {
    margin-bottom: var(--spacing-clear);
  }
  &:empty {
    display: none;
  }
`;

const CaseStudiesOutcomeRoadmap = () => {
  return (
    <React.Fragment>
      <CaseStudyHeading title="Technology" />
      <CaseStudyProfile>
        <p>
          My portfolio website has been through many exicting iterations, as
          this is the playground for personal growth within the Front-End
          environment.
        </p>
        <p>
          When I decided to iterate on my website, I looked to use my personal
          reset and base CSS, MUI{" "}
          <Links href="https://michaeltrilford.github.io/" target="_blank">
            native web component project
          </Links>{" "}
          to provide base elements to build the experience, React CLI for
          bringing life to the website, CSS Variables to define Design Tokens
          and Styled-Components to introduce specific page layout and
          application component styles.
        </p>
      </CaseStudyProfile>
      <CaseStudiesCard>
        <Heading variant="large">Mui reset & base styles</Heading>
        <CaseStudyParagraph>
          Over the years of creating websites, I have meticulously crafted a CSS
          file overtime that has many design decisions embedded within it. I
          have used it at the very heart of this website since its inception,
          and I continue to use it today.
        </CaseStudyParagraph>
        <CaseStudyParagraph>
          The way people think about styling websites and applications has
          changed, and my latest addiction is to understand how that
          consolidated list of styles can be broken down into a lightweight base
          for any modern day website.
        </CaseStudyParagraph>
        <CaseStudyParagraph>
          When simplifying the Mui Reset and Base styles, I also had reusable
          chunks of CSS that would help speed up the process of building
          familiar UI, and we call these components. In the next section, I will
          talk about how I extracted these styles and arranged them in a way
          that can be ported to any modern-day Front-End stack, such as Native
          Web Components, Vue or React.
        </CaseStudyParagraph>
        <Rule spacing="medium" inverted />
        <Heading variant="large">Mui web components</Heading>
        <CaseStudyParagraph>
          I quickly found an issue using the web components within the React
          framework, as referencing the CSS reset within the shadow-dom fails to
          work. I have yet to determine the cause, but I'm assuming it has
          something with how React compiles the CSS files and the path shifts.
        </CaseStudyParagraph>
        <CodeElementBlock>
          <CodeElement>@import url("css/mui-reset.css");</CodeElement>
        </CodeElementBlock>
        <CaseStudyParagraph>
          I can either refactor my{" "}
          <Links href="https://michaeltrilford.github.io/" target="_blank">
            native web component project
          </Links>{" "}
          to have more of the reset styles directly within the component or look
          to port these native components over to a React component, which was
          my first intention.
        </CaseStudyParagraph>
        <CaseStudyParagraph>
          What I decide will certainly help shape the future for that personal
          project.
        </CaseStudyParagraph>
        <Rule spacing="medium" inverted />
        <Heading variant="large">React CLI, Github and Netlify</Heading>
        <CaseStudyParagraph>
          When I tweak my website or update some content, I will do the
          following to push the changes live.
        </CaseStudyParagraph>
        <ol>
          <li>Run a build in the terminal</li>
          <li>Commit that change to my personal Github account</li>
          <li>Netlify will then deploy the changes to michaeltrilford.co.nz</li>
        </ol>
        <Rule spacing="medium" inverted />
        <Heading variant="large">CSS Variables</Heading>
        <ColumnLayout mb="clear" gridTemplateColumns={{ lg: "1fr 1fr" }}>
          <Image
            dark
            src={TechnologyUsedDesignTokens}
            alt="Agnostic design tokens"
            caption="Agnostic design tokens"
            spacing="clear"
          />
          <div>
            <CaseStudyParagraph>
              Since I'm only supporting modern browsers on my website, I have
              opted to use CSS Variables as my Design Token Library. The naming
              convention adopted here is different from the Feelix approach, as
              I wasn't limited on the JSON structure.
            </CaseStudyParagraph>
            <CaseStudyParagraph>
              The tokens consist of a foundational set, which reference colours,
              font sizes, etc. Then these foundation tokens are mapped to
              specific uses cases, such as the colour of a link.
            </CaseStudyParagraph>
          </div>
        </ColumnLayout>
        <Rule spacing="medium" inverted />
        <Heading variant="large">Styled-Components</Heading>
        <CaseStudyParagraph>
          Working with Styled-Components is terrific, as I find it helps to
          improve the overall architecture of the codebase.
        </CaseStudyParagraph>

        <CaseStudyParagraph>
          If you are familiar with Sass, then you will feel right at home, as
          you have access to nesting and other useful techniques. The bonus with
          Styled-Components is that any style introduced is encapsulated within
          that specific file.
        </CaseStudyParagraph>

        <CaseStudyParagraph>
          You can continue to use classNames to style your custom elements or
          slight modifications to existing components.
        </CaseStudyParagraph>

        <CaseStudyParagraph>
          Alternatively, you can use Styled-Components to declare a component
          that has specific styles associated with it, that you can reuse within
          that document.
        </CaseStudyParagraph>
        <CodeElementBlock>
          <CodeElement>const ContainerElement = styled.div</CodeElement>
        </CodeElementBlock>
        <CaseStudyParagraph>
          The great thing about this, it helps identify new components that you
          could lift out of the specific file, and add globally to your website
          or application so that you can use it elsewhere.
        </CaseStudyParagraph>
        <Rule spacing="medium" inverted />

        <Heading variant="large">Styled-System</Heading>
        <CaseStudyParagraph>
          <Links
            href="https://styled-system.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Styled System
          </Links>{" "}
          provides style API's to give users a way to attach additional styles
          to React components when wanting to craft spacing, layout, the
          typography of an experience.
        </CaseStudyParagraph>

        <Heading variant="small" mb="smaller">
          Utilising the theme
        </Heading>
        <CaseStudyParagraph>
          With the CSS Variables (design tokens) I mentioned earlier, I have
          mapped them to the styled-components `Theme.js` file. I have opted for
          a more refined naming convention, which I will introduce to my CSS
          Variables in future. The good thing is, they're agnostic, so I can map
          them together, and iterate on that naming convention at another time.
        </CaseStudyParagraph>
        <CodeElementBlock>
          <CodeElement>
            &#47;&#47; Theme.js snippet
            <br />
            <br />
            space: &#123;
            <br />
            &nbsp;&nbsp;largest: "var(--spacing-x-large)",
            <br />
            &nbsp;&nbsp;larger: "var(--spacing-large)",
            <br />
            &nbsp;&nbsp;large: "var(--spacing-x-medium)",
            <br />
            &nbsp;&nbsp;medium: "var(--spacing-medium)",
            <br />
            &nbsp;&nbsp;small: "var(--spacing-small)",
            <br />
            &nbsp;&nbsp;smaller: "var(--spacing-x-small)",
            <br />
            &nbsp;&nbsp;smallest: "var(--spacing-tiny)",
            <br />
            &nbsp;&nbsp;clear: "var(--spacing-clear)",
            <br />
            &#125;
          </CodeElement>
        </CodeElementBlock>

        <Heading variant="small" mb="smaller">
          Simple styled-system components
        </Heading>
        <CaseStudyParagraph>
          You can create powerful layout components by simple combining
          Styled-components and Styled-system together. The seemingly inline
          style will transpile into the application head element, so the CSS is
          performant.
        </CaseStudyParagraph>
        <CodeElementBlock>
          <CodeElement>
            const Box = styled.div`
            <br />
            &nbsp;&nbsp;${`{space}`}
            <br />
            &nbsp;&nbsp;${`{layout}`}
            <br />
            &nbsp;&nbsp;${`{color}`}
            <br />
            &nbsp;&nbsp;${`{flexbox}`}
            <br />
            `;
          </CodeElement>
          <CodeElement>&#60;Box marginBottom="small" /&#62; `;</CodeElement>
        </CodeElementBlock>
        <Heading variant="small" mb="smaller">
          Complex styled-system components
        </Heading>
        <CaseStudyParagraph>
          Remember to spread the props when installing styled-components inside
          your React components. Additionally, add the styled-system API's at
          the end of the styled-components styles, as this will allow the custom
          styles to override the foundation styles naturally.
        </CaseStudyParagraph>
        <CodeElementBlock>
          <CodeElement>
            const LinkElement = styled.a`
            <br />
            &nbsp;&nbsp;... relevant styles ...
            <br />
            &nbsp;&nbsp;${`{space}`}
            <br />
            &nbsp;&nbsp;${`{layout}`}
            <br />
            &nbsp;&nbsp;${`{color}`}
            <br />
            &nbsp;&nbsp;${`{flexbox}`}
            <br />
            `;
          </CodeElement>
          <CodeElement>
            const Link = &#40;&#123;
            <br />
            &nbsp;&nbsp;children,
            <br />
            &nbsp;&nbsp;...props
            <br />
            &#125;&#41; =&#62; &#123;
            <br />
            &nbsp;&nbsp;return &#40;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&#60;LinkElement&nbsp;&#123;...props&#125;&#62;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;children&#125;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&#60;/LinkElement&#62;
            <br />
            &nbsp;&nbsp;&#41;;
            <br />
            &#125;;
          </CodeElement>
        </CodeElementBlock>
      </CaseStudiesCard>
    </React.Fragment>
  );
};

export default CaseStudiesOutcomeRoadmap;
