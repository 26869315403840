import React from "react";
import styled, { css } from "styled-components";
import Icons from "../../Icons";
import Badge from "../../Badge";
import Heading from "../../Heading";

const SkillsBadge = styled(Badge)``;

const SkillsItemEl = styled.div`
  margin-bottom: 4.5em;
  *:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.small &&
    css`
      margin-bottom: var(--spacing-clear);
      h6 {
        margin-bottom: var(--spacing-x-tiny);
        text-align: center;
      }
      p {
        margin-bottom: var(--spacing-clear);
        text-align: center;
        font-size: var(--font-size-text-small);
      }
      ${SkillsBadge} {
        margin-left: auto;
        margin-right: auto;
      }
    `};
`;

const SkillsItem = ({
  className,
  title,
  icon,
  description,
  tooltip,
  small,
}) => {
  return small ? (
    <SkillsItemEl small={small} className={className}>
      <SkillsBadge tooltip={tooltip} small={small} title={title}>
        <Icons type={`${icon}`} />
      </SkillsBadge>
      <Heading variant="smallest">{title}</Heading>
      <p>{description}</p>
    </SkillsItemEl>
  ) : (
    <SkillsItemEl className={className}>
      <Badge tooltip={tooltip} title={title}>
        <Icons type={`${icon}`} />
      </Badge>
      <Heading variant="large">{title}</Heading>
      <hr />
      <p>{description}</p>
    </SkillsItemEl>
  );
};
export default SkillsItem;
