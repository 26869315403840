import React from "react";
import styled from "styled-components";
import Icons from "../../../Icons";
import Badge from "../../../Badge";
import ProjectTitle from "../ProjectTitle";
import ProjectSection from "../ProjectSection";
import ProjectSectionSkills from "../ProjectSectionSkills";
import ProjectDescription from "../ProjectDescription";
import ProjectHeroImage from "../ProjectHeroImage";
import MobileBanking1 from "../../../../img/mobile-banking-1.jpg";
import MobileBanking2 from "../../../../img/mobile-banking-2.jpg";

const BankingProjectSection = styled(ProjectSection)`
  background: #404041;
`;

function MobileBanking() {
  return (
    <BankingProjectSection>
      <ProjectTitle title="Mobile Banking" />
      <ProjectSectionSkills>
        <Badge small title="UI Design">
          <Icons type="rgba-small" />
        </Badge>
        <Badge small title="UX Design">
          <Icons type="ux-small" />
        </Badge>
      </ProjectSectionSkills>
      <ProjectDescription>
        When running a small business, there are some tasks a business owner is
        required to do, which helps with their tax compliance tasks. One of
        these tasks is reconciling their expenses to their bank transactions
        that come into their accounting system. To ensure the experience is an
        enjoyable and most efficient, the experience is designed to be less of a
        data-entry task, and more of a simple review.
      </ProjectDescription>
      <ProjectHeroImage
        srcMobile1={MobileBanking1}
        srcMobile2={MobileBanking2}
        mobile
      />
    </BankingProjectSection>
  );
}

export default MobileBanking;
