import React from "react";
import styled, { css } from "styled-components";

const FigureElement = styled.figure`
  margin-bottom: 0;
  border-radius: var(--radius-card);
  overflow: hidden;
  width: 100%;
  background: #eee;

  img {
    width: 100%;
    height: auto;
  }

  ${(props) =>
    props.size === "small" &&
    css`
      width: 40%;
    `}

  ${(props) =>
    props.dark &&
    css`
      background: var(--dark-grey-palette);
    `}

  ${(props) =>
    props.align === "left" &&
    css`
      width: 50%;
      margin-right: var(--spacing-medium);
      float: left;
    `}

  ${(props) =>
    props.align === "right" &&
    css`
      width: 50%;
      margin-left: var(--spacing-medium);
      float: right;
    `}

  ${(props) =>
    props.spacing === "clear" &&
    css`
      @media (min-width: 500px) {
        margin-bottom: var(--spacing-clear);
      }
    `}

  ${(props) =>
    props.spacing === "medium" &&
    css`
      margin-bottom: var(--spacing-medium);
    `}

  ${(props) =>
    props.spacing === "large" &&
    css`
      margin-bottom: var(--spacing-large);
    `}
`;

const FigureCaptionElement = styled.figcaption`
  color: var(--text-body-color);
  padding: var(--spacing-medium);
  text-align: center;

  ${(props) =>
    props.dark &&
    css`
      background: var(--dark-grey-palette);
      color: var(--white-palette);
    `}
`;

const Image = ({
  children,
  align,
  size,
  dark,
  caption,
  src,
  alt,
  className,
  spacing,
}) => {
  return (
    <FigureElement
      align={align}
      dark={dark}
      size={size}
      className={className}
      spacing={spacing}
    >
      {children}
      <img src={src} alt={alt} />
      {caption && (
        <FigureCaptionElement dark={dark}>{caption}</FigureCaptionElement>
      )}
    </FigureElement>
  );
};

export default Image;
