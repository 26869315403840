import React from "react";
import styled from "styled-components";
import DesignSystemsProfile from "../../../img/michaeltrilford.jpeg";

const CaseStudyProfileImg = styled.div`
  width: 20rem;
  margin: var(--spacing-x-large) auto var(--spacing-large);
  img {
    width: 100%;
    height: auto;
    min-height: 20rem;
  }
  @media (min-width: 510px) {
    margin: 0 auto;
  }
  border-radius: 100%;
  overflow: hidden;
  box-shadow: 0 0 0 8px #3b3b39;
`;

const CaseStudyProfileInformation = styled.div`
  margin: 0;
  max-width: 64ch;
  font-family: var(--font-family);
  text-align: center;
  @media (min-width: 510px) {
    text-align: left;
  }
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const CaseStudyProfileElement = styled.div`
  margin: 0;
  margin-bottom: var(--spacing-xx-large);
  @media (min-width: 510px) {
    align-items: start;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 4.8rem;
    padding-top: var(--spacing-x-medium);
  }
  @media (min-width: 600px) {
    align-items: center;
    grid-template-columns: 200px 1fr;
  }
`;

const CaseStudiesProfile = ({ children }) => {
  return (
    <CaseStudyProfileElement>
      <CaseStudyProfileImg>
        <img alt="Michael Trilford Profile" src={DesignSystemsProfile} />
      </CaseStudyProfileImg>
      <CaseStudyProfileInformation>{children}</CaseStudyProfileInformation>
    </CaseStudyProfileElement>
  );
};

export default CaseStudiesProfile;
