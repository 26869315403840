import React from "react";
import styled, { css } from "styled-components";
import Rule from "../../../Rule";
import Image from "../../../Image";
import Heading from "../../../Heading";
import CaseStudiesIntroduction from "../CaseStudiesIntroduction";
import ColumnLayout from "../ColumnLayout";
import CaseStudiesCard from "../CaseStudiesCard";
import DesignSystemsDesignTokens from "../../../../img/casestudy/design-systems/design-system-design-tokens.jpg";

const CaseStudyDetailItem = styled.div`
  p,
  ol,
  ul {
    font-size: var(--font-size-text-small);
  }
`;

const CaseStudyExample = styled.p`
  padding: 1.6rem;
  border-radius: var(--radius-card);
  border: var(--border-thin-inverted);
  position: relative;
  margin-top: 3.2rem;
  background: #2d2d2d;
  font-size: var(--font-size-text-small);
  &:before {
    content: "Example";
    position: absolute;
    top: -12px;
    padding: 0 8px;
    background: #242424;
    background: linear-gradient(180deg, #242424 50%, #2d2d2d 50%);
    left: 8px;
  }
`;

const TokenStructure = styled.span`
  font-size: var(--font-size-text);
  font-family: monospace;
  ${(props) =>
    props.prefix &&
    css`
      color: #adffad;
    `}
  ${(props) =>
    props.property &&
    css`
      color: #55ecff;
    `}
  ${(props) =>
    props.component &&
    css`
      color: #ffc355;
    `}
`;

const TokenStructureKeyWrapper = styled.div`
  margin-bottom: var(--spacing-clear);
  @media (min-width: 510px) {
    display: grid;
    grid-template-columns: auto auto auto;
    margin-top: var(--spacing-small);
  }
`;

const TokenStructureIndividualKey = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-tiny);
  @media (min-width: 510px) {
    margin-bottom: var(--spacing-clear);
  }
`;

const TokenStructureIndividualKeyText = styled.span`
  margin-left: 0.8rem;
  font-size: var(--font-size-text-tiny);
`;

const TokenStructureKey = styled.span`
  width: 12px;
  height: 12px;
  background: blue;
  display: block;
  ${(props) =>
    props.prefix &&
    css`
      background: #adffad;
    `}
  ${(props) =>
    props.property &&
    css`
      background: #55ecff;
    `}
  ${(props) =>
    props.component &&
    css`
      background: #ffc355;
    `};
`;

const FeelixDesignTokens = (
  <CaseStudiesCard id="designtokens">
    <CaseStudiesIntroduction>
      <Heading>Feelix Design Tokens</Heading>
      <p>
        In late 2015, MYOB decided that React is our default engineering
        environment for building applications. When we formed as a Design
        System's team, our decision was easy; we were to move away from
        supplying traditional HTML and CSS snippets and provide a library of
        components built in React.
      </p>
      <Rule spacing="medium" inverted />
    </CaseStudiesIntroduction>
    <ColumnLayout gridTemplateColumns="1fr 1fr">
      <div>
        <CaseStudyDetailItem>
          <Image
            dark
            src={DesignSystemsDesignTokens}
            alt="Agnostic design tokens"
            caption="Agnostic design tokens"
          />
        </CaseStudyDetailItem>
      </div>
      <div>
        <p>
          With this move to React componentry, it was our duty to enable teams
          to have access to the foundation of our design system still. Hence, we
          needed a way to easily gift foundational styles to align with the
          brand on any technology stack.
        </p>
        <p>
          This ability to being agnostic is made possible by the concept of
          Design Tokens, which are stored design decisions or variables that
          consist of a single design decision, such as a defined colour or font
          size.
        </p>
        <p>
          The introduction of Design Tokens also allows the Feelix Design System
          to use, so we can ensure the scalability and effort of adjusting the
          visual aesthetic of the system is most efficient.
        </p>

        <CaseStudyExample>
          If a brand update was to occur, the values defined in the root Design
          Token file could be adjusted; then the design decision will ripple
          across all of the instances it is declared.
        </CaseStudyExample>
        <p>
          Feelix used a tool called Styled-Dictionary, which uses a JSON file to
          create the range of Design Tokens Feelix offers. The structure of the
          JSON file influenced how we went about the naming convention of our
          design tokens, which follows this pattern.
        </p>
        <CaseStudyExample>
          <TokenStructure prefix>flx</TokenStructure>-
          <TokenStructure property>font-size</TokenStructure>-
          <TokenStructure component>button</TokenStructure>
          <Rule spacing="small" inverted />
          <TokenStructureKeyWrapper>
            <TokenStructureIndividualKey>
              <TokenStructureKey prefix />
              <TokenStructureIndividualKeyText>
                Feelix prefix
              </TokenStructureIndividualKeyText>
            </TokenStructureIndividualKey>
            <TokenStructureIndividualKey>
              <TokenStructureKey property />
              <TokenStructureIndividualKeyText>
                Property name
              </TokenStructureIndividualKeyText>
            </TokenStructureIndividualKey>
            <TokenStructureIndividualKey>
              <TokenStructureKey component />
              <TokenStructureIndividualKeyText>
                Component name
              </TokenStructureIndividualKeyText>
            </TokenStructureIndividualKey>
          </TokenStructureKeyWrapper>
        </CaseStudyExample>
        <p>
          Since the introduction of Design Tokens, there have been numerous
          feature requests related to our Design Token offerings, thus
          showcasing the impact and value this single feature can provide.
        </p>
      </div>
    </ColumnLayout>
  </CaseStudiesCard>
);

export default FeelixDesignTokens;
