import React from "react";
import styled, { css } from "styled-components";

const CaseStudyCardElement = styled.div`
  margin-left: -3.6rem;
  margin-right: -3.6rem;
  width: calc(100% + 7.2rem);
  padding: 3.6rem;
  @media (min-width: 1024px) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding: 4.8rem;
  }
  border-radius: 24px;
  background: #00000021;
  box-shadow: 0 34px 30px 20px rgba(0, 0, 0, 0.2);
  border: 2px solid #2b2b2a;
  margin-bottom: var(--spacing-xx-large);

  p,
  ul,
  ol {
    color: #bfbfbf;
    font-family: var(--font-family);
  }

  ${(props) =>
    props.spacing === "clear" &&
    css`
      margin-bottom: var(--spacing-clear);
    `}

  ${(props) =>
    props.spacing === "small" &&
    css`
      margin-bottom: var(--spacing-small);
    `}

  ${(props) =>
    props.spacing === "medium" &&
    css`
      margin-bottom: var(--spacing-medium);
    `}

  ${(props) =>
    props.spacing === "large" &&
    css`
      margin-bottom: var(--spacing-large);
    `}
`;

const CaseStudiesCard = ({ children, spacing, className, id }) => {
  return (
    <CaseStudyCardElement id={id} spacing={spacing} className={className}>
      {children}
    </CaseStudyCardElement>
  );
};

export default CaseStudiesCard;
