import React from "react";
import CaseStudyHeading from "./CaseStudiesHeading";
import CaseStudyProfile from "./CaseStudiesProfile";

// Sections
import Typography from "./DesignSystem/TypographyOnTheWeb";
import DesignProcess from "./DesignSystem/DesignProcess";
import Introduction from "./DesignSystem/Introduction";
import DesigningTheRoadmap from "./DesignSystem/DesigningTheRoadmap";
import DesigningContributionModel from "./DesignSystem/DesigningContribution";
import BuildOrBuy from "./DesignSystem/BuildOrBuy";
import FeelixDesignTokens from "./DesignSystem/FeelixDesignTokens";
// import CustomerValueMap from "./DesignSystem/CustomerValueMap";

const CaseStudiesDesignSystems = () => {
  return (
    <React.Fragment>
      <CaseStudyHeading title="Design systems" />
      <CaseStudyProfile>
        <p>
          I love designing, coding and architecting high-quality Design Systems
          within organisations. This passion ensures the final product is
          intuitive and quickly adopted internally by developers and designers.
          This ease-of-use provides direct impact to end-customers of a product,
          as the value teams create can be shipped faster while maintaining the
          quality, consistency and robust micro-interactions that are core to a
          Design System.
        </p>
      </CaseStudyProfile>
      {Introduction}
      {DesigningTheRoadmap}
      {DesignProcess}
      {/* {CustomerValueMap} */}
      {BuildOrBuy}
      {FeelixDesignTokens}
      {DesigningContributionModel}
      {Typography}
    </React.Fragment>
  );
};

export default CaseStudiesDesignSystems;
