import React from "react";
import styled from "styled-components";
import Rule from "../../../Rule";
import Image from "../../../Image";
import Heading from "../../../Heading";
import CaseStudiesIntroduction from "../CaseStudiesIntroduction";
import CaseStudiesCard from "../CaseStudiesCard";
import DesignSystemsBuildVsBuy from "../../../../img/casestudy/design-systems/design-system-build-vs-buy.png";
import DesignSystemsBuildVsBuyKendo from "../../../../img/casestudy/design-systems/design-system-build-vs-buy-kendo.png";
import DesignSystemsBuildVsBuyFeelix from "../../../../img/casestudy/design-systems/design-system-build-vs-buy-feelix.png";
import Gridbox from "../../../Gridbox";
import Box from "../../../Box";

const BuildOrBuyImage = styled(Image)`
  margin-bottom: 0;
  ul {
    text-align: left;
    font-size: var(--font-size-text-small);
    list-style: none;
    padding: var(--spacing-clear);
  }
`;

const BuildOrBuy = (
  <CaseStudiesCard id="buildvsbuy">
    <CaseStudiesIntroduction>
      <Heading marginBottom="large">Build vs Buy a Design System?</Heading>
      <Gridbox
        gridTemplateColumns={{ sm: "1fr", md: "1fr 2fr" }}
        gridGap="larger"
      >
        <Box mb="clear">
          <p>
            Our stakeholders asked the question whether we should continue to
            use our current system that was born from a labour of love, or start
            fresh with a system off the shelf.
          </p>
          <p>
            We kicked off a series of tasks to understand if building or buying
            was a viable option for MYOB and our customers. In the end, the
            Feelix Design System had many positives that determined the
            continued investment, which we have been iterating on since 2018.
          </p>
          <p>
            Feelix is much more mature and a better experience for our customers
            in 2019. We continue to improve this system in our day-to-day work,
            which will enable MYOB to deliver quality experiences to our
            customers faster.
          </p>
        </Box>
        <Gridbox
          gridTemplateColumns={{ sm: "1fr 1fr" }}
          gridGap="larger"
          mb="clear"
        >
          <Box>
            <BuildOrBuyImage
              dark
              src={DesignSystemsBuildVsBuyKendo}
              alt="Kendo overview"
              caption={
                <>
                  <Heading variant="smallest">Kendo overview</Heading>
                  <Rule inverted />
                  <ul>
                    <li>
                      <strong>0%</strong> open-source components
                    </li>
                    <li>
                      Branding <strong>0%</strong> completed
                    </li>
                    <li>
                      <strong>10</strong> React components
                    </li>
                    <li>
                      <strong>31</strong> jQuery components
                    </li>
                    <li>
                      <strong>41</strong> total components
                    </li>
                  </ul>
                </>
              }
            />
          </Box>
          <Box>
            <BuildOrBuyImage
              dark
              src={DesignSystemsBuildVsBuyFeelix}
              alt="Feelix overview"
              caption={
                <>
                  <Heading variant="smallest">Feelix overview</Heading>
                  <Rule inverted />
                  <ul>
                    <li>
                      <strong>24%</strong> open-source components
                    </li>
                    <li>
                      Branding <strong>100%</strong> completed
                    </li>
                    <li>
                      <strong>44</strong> React components
                    </li>
                    <li>
                      <strong>0</strong> jQuery components
                    </li>
                    <li>
                      <strong>44</strong> total components
                    </li>
                  </ul>
                </>
              }
            />
          </Box>
        </Gridbox>
      </Gridbox>
    </CaseStudiesIntroduction>
    <BuildOrBuyImage
      dark
      src={DesignSystemsBuildVsBuy}
      alt="Build vs Buy comparison map"
      caption="Build vs Buy comparison map"
    />
  </CaseStudiesCard>
);

export default BuildOrBuy;
