import React from "react";
import styled from "styled-components";
import Icons from "../../../Icons";
import Badge from "../../../Badge";
import ProjectTitle from "../ProjectTitle";
import ProjectSection from "../ProjectSection";
import ProjectSectionSkills from "../ProjectSectionSkills";
import ProjectDescription from "../ProjectDescription";
import ProjectHeroImage from "../../Projects/ProjectHeroImage";
import SightsImage from "../../../../img/sights.jpg";

const SightsProjectSection = styled(ProjectSection)`
  background: #87412f;
`;

function Sights() {
  return (
    <SightsProjectSection>
      <ProjectTitle title="Anzac Sights &amp; Sounds" />
      <ProjectSectionSkills>
        <Badge small title="UI Design">
          <Icons type="rgba-small" />
        </Badge>
        <Badge small title="HTML">
          <Icons type="html-small" />
        </Badge>
        <Badge small title="Sass">
          <Icons type="sass-small" />
        </Badge>
        <Badge small title="Agile Process">
          <Icons type="agile-small" />
        </Badge>
        <Badge small title="UX Design">
          <Icons type="ux-small" />
        </Badge>
      </ProjectSectionSkills>
      <ProjectDescription>
        This site explores the sights & sounds of World War I before, during &
        after the war. The clients' focus was to present the imagery, video &
        audio content excitingly. My objective was to let the content shine, so
        the design direction is built around this concept.
      </ProjectDescription>

      <ProjectHeroImage desktop src={SightsImage} />
    </SightsProjectSection>
  );
}

export default Sights;
