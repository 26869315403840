import React from "react";
import styled from "styled-components";
import Icons from "../../../Icons";
import Badge from "../../../Badge";
import Links from "../../../Links";
import ProjectTitle from "../ProjectTitle";
import ProjectSection from "../ProjectSection";
import ProjectSectionSkills from "../ProjectSectionSkills";
import ProjectDescription from "../ProjectDescription";
import ProjectHeroImage from "../ProjectHeroImage";
import CaseStudiesImage from "../../../../img/case-studies.png";

const CaseStudiesProjectSection = styled(ProjectSection)`
  background: #ba349a;
`;

const CaseStudyLink = styled(Links)`
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  &:hover,
  &:focus {
    color: white;
    border-bottom: none;
  }
  &:focus {
    outline-color: white;
  }
`;

function CaseStudies() {
  return (
    <CaseStudiesProjectSection>
      <ProjectTitle title="Case Studies" />

      <ProjectSectionSkills>
        <Badge small title="UI Design">
          <Icons type="rgba-small" />
        </Badge>
        <Badge small title="HTML">
          <Icons type="html-small" />
        </Badge>
        <Badge small title="CSS">
          <Icons type="css-small" />
        </Badge>
        <Badge small title="React">
          <Icons type="react-small" />
        </Badge>
      </ProjectSectionSkills>
      <ProjectDescription>
        I have recently created a new section on my website called{" "}
        <CaseStudyLink
          href="/case-studies"
          target="_blank"
          rel="noopener noreferrer"
        >
          Case Studies
        </CaseStudyLink>
        , where I showcase work from the past two years working at MYOB as a
        Senior Product Designer, and the thinking and processes involved.
      </ProjectDescription>

      <ProjectHeroImage src={CaseStudiesImage} desktop />
    </CaseStudiesProjectSection>
  );
}

export default CaseStudies;
