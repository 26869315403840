import React from "react";
import styled from "styled-components";
import CaseStudyHeading from "../CaseStudies/CaseStudiesHeading";

const ResumeHeadingStyle = styled(CaseStudyHeading)`
  margin-bottom: var(--spacing-x-tiny);
  grid-column: 1/1;
  grid-row: 1/1;
  h1 {
    font-size: var(--font-size-heading-x-large);
  }
`;

const ResumeHeading = ({ title }) => {
  return <ResumeHeadingStyle title={title} />;
};

export default ResumeHeading;
