import React from "react";
import styled from "styled-components";
import Heading from "../../Heading";

const HeaderElement = styled(Heading)`
  margin-top: var(--spacing-small);
`;

const TagElement = styled.a`
  background: rgba(0, 0, 0, 0.12);
  padding: var(--spacing-tiny) var(--spacing-x-small);
  border-radius: var(--radius-label);
  line-height: var(--line-height-reset);
  text-transform: uppercase;
  font-size: var(--font-size-text-tiny);
  margin-top: var(--spacing-tiny);
  color: var(--palette-white);
  text-decoration: none;
  display: inline-block;
  &:hover,
  &:visited,
  &:focus {
    color: var(--palette-white);
  }
  @media (min-width: 600px) {
    margin-top: var(--spacing-clear);
    margin-left: var(--spacing-small);
  }
`;

const TagElementWrapper = styled.div`
  margin-top: var(--spacing-small);
  @media (min-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ${HeaderElement} {
    margin: var(--spacing-clear);
    width: auto;
  }
`;

const RuleElement = styled.hr`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const ProjectTitle = ({ title, tag, tagLink }) => {
  return (
    <>
      {tag ? (
        <TagElementWrapper>
          <HeaderElement>{title}</HeaderElement>
          <TagElement target="_blank" href={tagLink}>
            {tag}
          </TagElement>
        </TagElementWrapper>
      ) : (
        <HeaderElement>{title}</HeaderElement>
      )}

      <RuleElement />
    </>
  );
};

export default ProjectTitle;
