import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import skillsImage from "../skills-badge.svg";

const BadgeElement = styled.div`
  border-radius: 100%;
  position: relative;
  width: 8.3125em;
  height: 8.3125em;
  background: url(${skillsImage}) 0 0 no-repeat;
  margin: 0px auto 1.5em;

  ${props =>
    props.small &&
    css`
      height: 7.2rem;
      width: 7.2rem;
      background: rgba(0, 0, 0, 0.05);
      box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.5),
        0 -1px 0 0 rgba(255, 255, 255, 0.1);
      margin: 4rem 1.4rem 2.4rem;

      @media (pointer: fine) {
        margin-top: 0;
        cursor: help;
      }

      @media (pointer: coarse) {
        margin-left: 2.4rem;
        margin-right: 2.4rem;
      }

      @media only screen and (min-width: 768px) {
        margin-left: 1.2rem;
        margin-right: 1.2rem;
      }

      &:before {
        border: solid;
        border-color: rgba(0, 0, 0, 0.5) transparent;
        border-width: 0.6rem 0.6rem 0 0.6rem;
        bottom: 8.2rem;
        content: "";
        left: 3.6rem;
        position: absolute;
        z-index: 99;
        margin-left: -0.6rem;
        display: block;
      }

      @media (pointer: fine) {
        &:before {
          display: none;
        }
        &:hover:before {
          display: block;
        }
      }

      &:after {
        background: rgba(0, 0, 0, 0.5);
        border-radius: 0.5rem;
        bottom: 8.8rem;
        color: #fff;
        content: attr(title);
        display: block;
        left: -0.9rem;
        padding: 0px;
        position: absolute;
        z-index: 98;
        text-align: center;
        width: 9rem;
        line-height: 2.4rem;
        height: 2.4rem;
        font-size: 1.1rem;
        outline: none;
        display: block;
      }

      @media (pointer: fine) {
        &:after {
          display: none;
        }
      }

      @media (pointer: fine) {
        &:hover:after {
          display: block;
        }
      }
    `};

  ${props =>
    props.large &&
    css`
      margin-bottom: 4.8rem;
      height: 26.6rem;
      width: 26.6rem;
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzMTQgMzE0IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAzMTQgMzE0IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+CgkKCQk8Y2lyY2xlIGZpbGw9Im5vbmUiIHN0cm9rZT0iI0ZGRkZGRiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IjMuOTkzMywzLjk5MzMiIGN4PSIxNTcuNDQzIiBjeT0iMTU3LjEyMyIgcj0iMTQ2LjE3MiIvPgo8L2c+Cjwvc3ZnPg==)
          no-repeat 0 0,
        #202020;
    `};

  ${props =>
    props.tooltip === "large" &&
    css`
      &:after {
        left: -2.2rem;
        width: 12rem;
      }
    `}
`;

const Badge = ({ className, small, large, children, title, tooltip }) => {
  return (
    <BadgeElement
      small={small}
      large={large}
      title={title}
      tooltip={tooltip}
      className={className}
    >
      {children}
    </BadgeElement>
  );
};

Badge.propTypes = {
  children: PropTypes.node
};

Badge.defaultProps = {
  children: undefined
};

export default Badge;
