import React from "react";
import styled from "styled-components";
import ProjectTitle from "../ProjectTitle";
import ProjectSection from "../ProjectSection";
import ProjectDescription from "../ProjectDescription";
import ProjectHeroImage from "../ProjectHeroImage";
import BladeRunner2049Image from "../../../../img/bladerunner2049.png";

const BladeRunnerProjectSection = styled(ProjectSection)`
  background: #304c4a;
  padding-bottom: var(--spacing-large);
`;

function BladeRunner2049() {
  return (
    <>
      <BladeRunnerProjectSection>
        <ProjectTitle title="Bladerunner 2049" />
        <ProjectDescription>
          With the introduction of SVG images and high-resolution screens,
          pixel-art is not a required skill in web design anymore. However; my
          passion for pixel-art has continued and here is a Bladerunner inspired
          illustration I have created.
        </ProjectDescription>
        <ProjectHeroImage src={BladeRunner2049Image} picture />
      </BladeRunnerProjectSection>
    </>
  );
}

export default BladeRunner2049;
