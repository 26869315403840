import React from "react";
import styled from "styled-components";

const ProjectSectionSkillsElement = styled.div`
  width: 30rem;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  @media (min-width: 500px) {
    width: 50rem;
  }

  @media (min-width: 768px) {
    width: 100%;
  }

  @media (pointer: coarse) {
    width: 37rem;
  }
`;

const ProjectSectionSkills = ({ children }) => {
  return (
    <ProjectSectionSkillsElement children={children}>
      {children}
    </ProjectSectionSkillsElement>
  );
};

export default ProjectSectionSkills;
