import React from "react";
import styled from "styled-components";
import Icons from "../../../Icons";
import Badge from "../../../Badge";
import ProjectTitle from "../ProjectTitle";
import ProjectSection from "../ProjectSection";
import ProjectSectionSkills from "../ProjectSectionSkills";
import ProjectDescription from "../ProjectDescription";

const CodepenProjectSection = styled(ProjectSection)`
  background: #b5914f;
`;

const CodepenIframe = styled.iframe`
  width: 100%;
  display: block;
  height: 45.2rem;
  padding: 5.106382978723%;
  padding-bottom: 0px;
  min-height: 14.41667rem;
  background: #000000;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  margin-bottom: 0;
  border: none;
`;

function CodepenGoogleGlass() {
  return (
    <CodepenProjectSection>
      <ProjectTitle
        title="Google Glass"
        tag="Codepen"
        tagLink="https://codepen.io/trilm/pen/QWjEjZd"
      />
      <ProjectSectionSkills>
        <Badge small title="UI Design">
          <Icons type="rgba-small" />
        </Badge>
        <Badge small title="HTML">
          <Icons type="html-small" />
        </Badge>
        <Badge small title="Sass">
          <Icons type="sass-small" />
        </Badge>
      </ProjectSectionSkills>
      <ProjectDescription>
        I created this composition back in 2012 when I first started to learn
        CSS. In 2020 I have decided to revisit the design and make it
        responsive. The Google Glass visual direction of this advert is a
        pastiche of the "X-Ray" vision glasses that would be seen within a comic
        book magazine from the '60s.
      </ProjectDescription>
      <CodepenIframe
        height="452"
        scrolling="no"
        title="Google glass"
        src="https://codepen.io/trilm/embed/QWjEjZd?height=300&theme-id=12869&default-tab=result"
        frameborder="no"
        allowtransparency="true"
        allowfullscreen="true"
      >
        See the Pen{" "}
        <a href="https://codepen.io/trilm/pen/QWjEjZd">Google glass</a> by
        Michael Trilford (<a href="https://codepen.io/trilm">@trilm</a>) on{" "}
        <a href="https://codepen.io">CodePen</a>.
      </CodepenIframe>
    </CodepenProjectSection>
  );
}

export default CodepenGoogleGlass;
