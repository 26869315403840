import React from "react";
import styled from "styled-components";
import Container from "../Container";
import Heading from "../Heading";
import Icons from "../Icons";
import Badge from "../Badge";
import "react-awesome-slider/dist/styles.css";
import "../../aws-slider.css";

var ReactRotatingText = require("react-rotating-text");

const HeaderElement = styled.header`
  margin-bottom: 0;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 0;
`;

const HeaderSubTitle = styled(Heading)`
  font-family: georgia;
  text-align: center;
  font-size: var(--font-size-heading-x-small);
  margin-top: var(--spacing-small);
  @media (min-width: 414px) {
    font-size: var(--font-size-heading-medium);
    margin-top: var(--spacing-clear);
  }
`;

const HeaderPassionReel = styled.span`
  color: #fffc00;
  padding: 0 0.375rem;
  font-style: italic;
  margin: 0;
  list-style: none;
`;

const HeaderPassionReelData = [
  "create tailor-made experiences",
  "explore ideas",
  "uncover insights",
  "validate hypotheses",
  "solve problems",
];

const rotatingText = (
  <ReactRotatingText cursor={false} items={HeaderPassionReelData} />
);

const Header = () => {
  return (
    <HeaderElement>
      <Container large center>
        <Badge title="Seal of high-quality & tailor-made experiences" large>
          <Icons type="bespokeNeedle" />
        </Badge>
        <HeaderWrapper>
          <Heading marginBottom="0.375rem" letterSpacing="-1px">
            Kia ora, I'm Mike Trilford.
          </Heading>
          <HeaderSubTitle variant="medium">
            I love to [ <HeaderPassionReel>{rotatingText}</HeaderPassionReel> ]
          </HeaderSubTitle>
        </HeaderWrapper>
      </Container>
    </HeaderElement>
  );
};

export default Header;
