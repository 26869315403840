import React from "react";
import styled, { css } from "styled-components";
import { space, color, layout, grid } from "styled-system";

const LinkElement = styled.a`
  color: #ff73df;
  border-bottom: 1px solid rgba(255, 115, 223, 0.3);
  text-decoration: none;
  &:hover,
  &:focus {
    color: #fffc01;
    border-bottom: 1px solid rgba(255, 115, 223, 0.3);
  }
  &:hover,
  &:focus {
    color: #ff73df;
    border-bottom: none;
  }

  &:focus {
    outline-color: #ff73df;
  }

  ${(props) =>
    props.button &&
    css`
      background-color: #333333;
      display: inline-block;
      text-align: center;
      color: white;
      height: auto;
      text-decoration: none;
      padding: 0px 1.5em;
      border-radius: 3.125em;
      line-height: 3;
      box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.5), 0 -1px 0 0 rgba(255, 255, 255, 0.1);
      border-bottom: none;
      &:hover {
        color: #ff73df;
    `}

  ${space}
  ${color}
  ${layout}
  ${grid}
`;

const Links = ({
  children,
  button,
  href,
  target,
  className,
  download,
  ...props
}) => {
  return (
    <LinkElement
      button={button}
      target={target}
      href={href}
      className={className}
      download={download}
      {...props}
    >
      {children}
    </LinkElement>
  );
};

export default Links;
