import React from "react";
import styled, { css } from "styled-components";
import Rule from "../../../Rule";
import Links from "../../../Links";
import Image from "../../../Image";
import Box from "../../../Box";
import Gridbox from "../../../Gridbox";
import Heading from "../../../Heading";
import CaseStudiesIntroduction from "../CaseStudiesIntroduction";
import CaseStudiesCard from "../CaseStudiesCard";
import DesignSystemsContribution from "../../../../img/casestudy/design-systems/design-system-contribution.png";
import Stack from "../../../Stack";

const CaseStudyGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(var(--grid-column), 1fr);
  background: #1c1c1c;
  margin-bottom: 0;
`;

const CreatingNewGrid = styled(CaseStudyGrid)`
  --grid-column: 5;
`;

const EnhanceExistingGrid = styled(CaseStudyGrid)`
  --grid-column: 4;
`;

const CaseStudyGridStep = styled.h6`
  width: auto;
  display: inline-block;
  color: #f56fd6;
  margin-bottom: 2px;
  margin-top: 0;

  ${(props) =>
    props.phase === "domain" &&
    css`
      color: #89e2fd;
    `}

  ${(props) =>
    props.phase === "2" &&
    css`
      color: #89e2fd;
    `}

    ${(props) =>
    props.phase === "platform" &&
    css`
      color: #fedd68;
    `}

    ${(props) =>
    props.phase === "4" &&
    css`
      color: #fedd68;
    `}

    ${(props) =>
    props.phase === "5" &&
    css`
      color: #fedd68;
    `}
`;

const Phase1Heading = styled.span`
  color: #49d5ff;
`;

const CaseStudyGridItem = styled.div`
  font-size: 1.3rem;
  padding: var(--spacing-medium);
  margin-bottom: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  &:nth-child(even) {
    background: #272727;
  }
`;

const CaseStudyGridItemHeaderGroup = styled.div`
    margin-bottom: var(--spacing-clear);
  }
`;

const CaseStudyGridHeading = styled.h4`
  background: #1c1c1c;
  padding: 1.6rem 2.4rem;
  text-align: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  grid-column: 1 / calc(var(--grid-column) + 1);
  margin: 0;
`;

const OverflowScrollShadow = styled.div`
  position: relative;
  &:before {
    position: absolute;
    width: 1px;
    height: 100%;
    background: rgb(0 0 0 / 48%);
    content: "";
    right: 0;
    box-shadow: -1px 0 1px 0 rgb(0 0 0 / 0.4), -2px 0 2px 0 rgb(0 0 0 / 0.3),
      -3px 0 3px 0 rgb(0 0 0 / 0.2);
  }
`;

const OverflowScroll = styled.div`
  overflow: scroll;
`;

const DesigningContributionModel = (
  <CaseStudiesCard id="contribution">
    <CaseStudiesIntroduction>
      <Heading>MYOB contribution model</Heading>
      <p>
        MYOB adopted a contribution model where teams interested in creating or
        enhancing Feelix components can do so directly within their applications
        or in a hosted repository. This approach allows teams to deliver value
        to end-customers without relying on another team.
      </p>

      <Image
        dark
        src={DesignSystemsContribution}
        alt="Sketch of the harvest model"
        caption="Sketch of the harvest model"
      />
    </CaseStudiesIntroduction>

    <Stack spacing="48px">
      <Box margin="0">
        <CaseStudyGridHeading id="contributingtofeelix">
          Components: Creating New
        </CaseStudyGridHeading>
        <OverflowScrollShadow style={{ marginBottom: 0 }}>
          <OverflowScroll style={{ marginBottom: 0 }}>
            <Box minWidth="1058px" style={{ marginBottom: 0 }}>
              <Gridbox gridTemplateColumns="2fr 3fr" mb="clear">
                <Box
                  backgroundColor="#89e2fd"
                  mb="clear"
                  padding="small"
                  textAlign="center"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Heading variant="medium" mb="clear" color="black">
                    Release customer value
                  </Heading>
                  <Box
                    as="p"
                    color="black !important"
                    maxWidth="34rem"
                    mb="smallest"
                  >
                    Test with the market
                  </Box>
                </Box>

                <Box
                  backgroundColor="#fedd68"
                  mb="clear"
                  padding="small"
                  textAlign="center"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Heading variant="medium" mb="clear" color="black">
                    Maintain quality of the system
                  </Heading>
                  <Box
                    as="p"
                    color="black !important"
                    maxWidth="42rem"
                    mb="smallest"
                  >
                    Harvest the successful components
                  </Box>
                </Box>
              </Gridbox>

              <CreatingNewGrid>
                <CaseStudyGridItem>
                  <CaseStudyGridStep phase="domain">Phase 1</CaseStudyGridStep>
                  <Heading variant="smaller" m="clear" width="12rem" mb="small">
                    Design, test & align
                  </Heading>
                  <Rule inverted />
                  <ol>
                    <li>Prove that Feelix can’t solve your problem</li>
                    <li>Critique the alternative solution(s) to Feelix</li>
                    <li>Circulate the alternative solution(s) to Feelix</li>
                    <li>Document the new solution in Labs</li>
                    <li>
                      Follow the{" "}
                      <Links href="#phaseOneSteps">
                        "Design, validate and align" steps
                      </Links>
                    </li>
                  </ol>
                </CaseStudyGridItem>

                <CaseStudyGridItem>
                  <CaseStudyGridStep phase="domain">Phase 2</CaseStudyGridStep>
                  <Heading variant="smaller" m="clear" width="14rem" mb="small">
                    Build & use the component
                  </Heading>
                  <Rule inverted />
                  <ol>
                    <li>
                      The designer has completed the required supporting
                      component documentation and there is clear alignment
                      within the team Leadership
                    </li>
                    <li>
                      The development team can begin building the new component
                      and then use the new experience to release the to market.
                    </li>
                  </ol>
                </CaseStudyGridItem>

                <CaseStudyGridItem>
                  <CaseStudyGridStep phase="platform">
                    Phase 3
                  </CaseStudyGridStep>
                  <Heading variant="smaller" m="clear" width="9rem" mb="small">
                    Submit to the DSC
                  </Heading>
                  <Rule inverted />
                  <ol>
                    <li>
                      Designers can propose a validated component for the Feelix
                      Design System.
                    </li>
                    <li>
                      Submit the working component and documentation outlining
                      the problem it solves to the DSC.
                    </li>
                    <li>
                      The DSC will review and decide if the proposal is
                      appropriate for Feelix.
                    </li>
                  </ol>
                </CaseStudyGridItem>

                <CaseStudyGridItem>
                  <CaseStudyGridStep phase="platform">
                    Phase 4
                  </CaseStudyGridStep>
                  <Heading variant="smaller" m="clear" width="9rem" mb="small">
                    Feelix adopts it
                  </Heading>
                  <Rule inverted />
                  <ol>
                    <li>
                      The data is showcasing broad adoption of the component
                    </li>
                    <li>A significant customer problem is solved</li>
                    <li>The component will be added to Feelix.</li>
                  </ol>
                </CaseStudyGridItem>
                <CaseStudyGridItem>
                  <CaseStudyGridStep phase="platform">
                    Phase 5
                  </CaseStudyGridStep>
                  <Heading variant="smaller" m="clear" width="9rem" mb="small">
                    Team replaces it
                  </Heading>
                  <Rule inverted />
                  <ol>
                    <li>
                      Feelix has added the component to the Design System.
                    </li>
                    <li>
                      The Design System team has requested the development team
                      to upgrade to the Feelix version with the newly added
                      component.
                    </li>
                  </ol>
                </CaseStudyGridItem>
              </CreatingNewGrid>
            </Box>
          </OverflowScroll>
        </OverflowScrollShadow>
      </Box>

      <Box margin="0">
        <CaseStudyGridHeading>
          Components: Enhancing Existing
        </CaseStudyGridHeading>
        <OverflowScrollShadow style={{ marginBottom: 0 }}>
          <OverflowScroll style={{ marginBottom: 0 }}>
            <Box minWidth="1058px" style={{ marginBottom: 0 }}>
              <Gridbox gridTemplateColumns="1fr 3fr" mb="clear">
                <Box
                  backgroundColor="#89e2fd"
                  mb="clear"
                  padding="small"
                  textAlign="center"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Heading variant="medium" mb="clear" color="black">
                    Create Prototype
                  </Heading>
                  <Box
                    as="p"
                    color="black !important"
                    maxWidth="22rem"
                    mb="smallest"
                  >
                    Test with the market
                  </Box>
                </Box>

                <Box
                  backgroundColor="#fedd68"
                  mb="clear"
                  padding="small"
                  textAlign="center"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Heading variant="medium" mb="clear" color="black">
                    Maintain quality of the system
                  </Heading>
                  <Box
                    as="p"
                    color="black !important"
                    maxWidth="42rem"
                    mb="smallest"
                  >
                    Harvest the successful enhancement
                  </Box>
                </Box>
              </Gridbox>

              <EnhanceExistingGrid>
                <CaseStudyGridItem>
                  <CaseStudyGridStep phase="domain">Phase 1</CaseStudyGridStep>
                  <Heading variant="smaller" m="clear" width="12rem" mb="small">
                    Design, test & align
                  </Heading>

                  <Rule inverted />

                  <ol>
                    <li>Prove that Feelix can’t solve your problem</li>
                    <li>Critique the alternative solution(s) to Feelix</li>
                    <li>Circulate the alternative solution(s) to Feelix</li>
                    <li>Document the new solution in Labs</li>
                    <li>
                      Follow the{" "}
                      <Links href="#phaseOneSteps">
                        "Design, validate and align" steps
                      </Links>
                    </li>
                  </ol>
                </CaseStudyGridItem>

                <CaseStudyGridItem>
                  <CaseStudyGridStep phase="platform">
                    Phase 2
                  </CaseStudyGridStep>
                  <Heading variant="smaller" m="clear" width="12rem" mb="small">
                    Submit to the DSC
                  </Heading>
                  <Rule inverted />
                  <ol>
                    <li>
                      Designers enhancing a Feelix component must add usage
                      guidelines and the Sketch file to the shared library.
                    </li>
                    <li>
                      They should share the design direction and component
                      template, explaining the problem it solves.
                    </li>
                    <li>
                      The DSC will review and discuss the enhancement. If there
                      are no concerns and all documentation is complete, the
                      enhancement is approved.
                    </li>
                  </ol>
                </CaseStudyGridItem>
                <CaseStudyGridItem>
                  <CaseStudyGridStep phase="platform">
                    Phase 3
                  </CaseStudyGridStep>
                  <Heading variant="smaller" m="clear" width="9rem" mb="small">
                    Feelix adopts it
                  </Heading>
                  <Rule inverted />

                  <ol>
                    <li>
                      The successful enhancement will be added to the Feelix
                      Coordination Board and can be prioritised accordingly.
                    </li>
                    <li>
                      Once Feelix has completed the enhanced Feelix component,
                      teams will be notified what version of Feelix it has been
                      added to, so they can begin the next phase.
                    </li>
                  </ol>
                </CaseStudyGridItem>
                <CaseStudyGridItem>
                  <CaseStudyGridStep phase="platform">
                    Phase 4
                  </CaseStudyGridStep>
                  <Heading variant="smaller" m="clear" width="14rem" mb="small">
                    Update & test Feelix version
                  </Heading>
                  <Rule inverted />
                  <ol>
                    <li>
                      The team has been notified what version of Feelix the
                      enhancement has been added to.
                    </li>
                    <li>
                      The team(s) can now plan for an appropriate time to
                      upgrade their version of Feelix to access the new feature
                      or behaviour.
                    </li>
                  </ol>
                </CaseStudyGridItem>
              </EnhanceExistingGrid>
            </Box>
          </OverflowScroll>
        </OverflowScrollShadow>
      </Box>

      <Box margin="0">
        <CaseStudyGridHeading id="phaseOneSteps">
          <Phase1Heading>Phase 1:</Phase1Heading> Test with the market
        </CaseStudyGridHeading>
        <OverflowScrollShadow style={{ marginBottom: 0 }}>
          <OverflowScroll overflow="scroll" style={{ marginBottom: 0 }}>
            <Box minWidth="1058px" style={{ marginBottom: 0 }}>
              <EnhanceExistingGrid style={{ marginBottom: 0 }}>
                <CaseStudyGridItem>
                  <CaseStudyGridItemHeaderGroup>
                    <CaseStudyGridStep phase="domain">Step 1</CaseStudyGridStep>
                    <Heading variant="smaller" mt="clear">
                      Prove that Feelix can’t solve your problem
                    </Heading>
                  </CaseStudyGridItemHeaderGroup>
                  <Rule inverted />
                  <Heading variant="smallest" margin="12px">
                    Touchpoints
                  </Heading>
                  <ul>
                    <li>Variant testing with users</li>
                    <li>Assigned critique</li>
                  </ul>
                  <Rule inverted />
                  <Heading variant="smallest" margin="12px">
                    Suggested actions
                  </Heading>
                  <ul>
                    <li>
                      Show why current components can’t solve the user problem.
                    </li>
                    <li>
                      Demonstrate how alternative solutions address the issue.
                    </li>
                    <li>Gather evidence to support your decisions.</li>
                    <li>Seek critique early.</li>
                  </ul>
                </CaseStudyGridItem>

                <CaseStudyGridItem>
                  <CaseStudyGridItemHeaderGroup>
                    <CaseStudyGridStep phase="domain">Step 2</CaseStudyGridStep>
                    <Heading variant="smaller" mt="clear">
                      Critique the alternative solution(s) to Feelix
                    </Heading>
                  </CaseStudyGridItemHeaderGroup>
                  <Rule inverted />
                  <Heading variant="smallest" margin="12px">
                    Touchpoints
                  </Heading>
                  <ul>
                    <li>Team</li>
                    <li>Assigned critique</li>
                  </ul>
                  <Rule inverted />
                  <Heading variant="smallest" margin="12px">
                    Suggested actions
                  </Heading>
                  <ul>
                    <li>
                      Show why current components can’t solve the problem.
                    </li>
                    <li>Demonstrate how alternative solutions work.</li>
                    <li>Share success/failure results of both.</li>
                    <li>Confirm technical feasibility.</li>
                  </ul>
                </CaseStudyGridItem>

                <CaseStudyGridItem>
                  <CaseStudyGridItemHeaderGroup>
                    <CaseStudyGridStep phase="domain">Step 3</CaseStudyGridStep>
                    <Heading variant="smaller" mt="clear">
                      Circulate the alternative solution(s) to Feelix
                    </Heading>
                  </CaseStudyGridItemHeaderGroup>
                  <Rule inverted />
                  <Heading variant="smallest" margin="12px">
                    Touchpoints
                  </Heading>
                  <ul>
                    <li>Team leadership</li>
                    <li>Design team on Slack</li>
                  </ul>
                  <Rule inverted />
                  <Heading variant="smallest" margin="12px">
                    Suggested actions
                  </Heading>
                  <ul>
                    <li>
                      Show how current Feelix components fail to solve the
                      problem.
                    </li>
                    <li>Show how alternative solutions work.</li>
                    <li>
                      Provide evidence that alternatives solve the problem.
                    </li>
                  </ul>
                </CaseStudyGridItem>

                <CaseStudyGridItem>
                  <CaseStudyGridItemHeaderGroup>
                    <CaseStudyGridStep phase="domain">Step 4</CaseStudyGridStep>
                    <Heading variant="smaller" mt="clear">
                      Document the new solution in Labs
                    </Heading>
                  </CaseStudyGridItemHeaderGroup>
                  <Rule inverted />
                  <Heading variant="smallest" margin="12px">
                    Touchpoints
                  </Heading>
                  <ul>
                    <li>MYOB Confluence</li>
                    <li>Design team on Slack</li>
                  </ul>
                  <Rule inverted />
                  <Heading variant="smallest" margin="12px">
                    Suggested actions
                  </Heading>
                  <ul>
                    <li>
                      Create a new page under “Labs components” on Confluence
                      using the “Labs template.”
                    </li>
                    <li>Add the Sketch file for the component to the page.</li>
                    <li>
                      Inform the design team and link them to the new component
                      for your engineering team.
                    </li>
                  </ul>
                </CaseStudyGridItem>
              </EnhanceExistingGrid>
            </Box>
          </OverflowScroll>
        </OverflowScrollShadow>
      </Box>
    </Stack>
  </CaseStudiesCard>
);

export default DesigningContributionModel;
