import React from "react";
import styled from "styled-components";
import Container from "../../Container";
import CaseStudiesDesignSprint from "./CaseStudiesDesignSprint";
import CaseStudiesDesignSystems from "./CaseStudiesDesignSystems";
import CaseStudiesStoryboards from "./CaseStudiesStoryboards";
import CaseStudiesTechnology from "./CaseStudiesTechnology";
import CaseStudiesCodeReminders from "./CaseStudiesCodeReminders";

const LeftAligned = styled.div`
  text-align: left;
`;

const CaseStudiesContainer = styled(Container)`
  max-width: 124rem;
  width: 100%;
  padding-top: var(--spacing-clear);
  @media (min-width: 1024px) {
    padding-top: var(--spacing-x-large);
  }
`;

const ScrollToSection = styled.div`
  margin-bottom: var(--spacing-x-large);
`;

const Grid = styled.div`
  padding: var(--spacing-large);
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 160px 1fr;
    grid-gap: var(--spacing-x-large);
    padding: var(--spacing-large);
    padding-top: var(--spacing-small);
  }
`;

const Aside = styled.aside`
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
  padding-top: var(--spacing-x-large);
  text-align: left;
  a {
    display: block;
    margin-bottom: var(--spacing-small);
  }
`;

const AsideSticky = styled.div`
  position: sticky;
  top: var(--spacing-x-medium);
`;

const BlogSectionVerticalSpace = styled.section`
  margin-bottom: var(--spacing-large);
`;

const StyledLinkButton = styled.a`
  color: #ff73df;
  text-decoration: none;
  &:hover,
  &:focus {
    color: #ff89e4;
  }
`;

const LevelTwoNav = styled.a`
  color: #ff73df;
  text-decoration: none;
  font-size: 14px;
  color: white;
  &:hover,
  &:focus {
    color: #ff89e4;
    cursor: pointer;
  }
`;

const CaseStudies = () => {
  return (
    <Grid>
      <Aside>
        <AsideSticky>
          <StyledLinkButton href="#designsystems">
            Design systems
          </StyledLinkButton>
          <LevelTwoNav href="#maturefeelix" style={{ marginBottom: 4 }}>
            <span style={{ opacity: 0.4, marginRight: 4 }}>»</span> Maturing
            Feelix
          </LevelTwoNav>
          <LevelTwoNav href="#designtheroadmap" style={{ marginBottom: 4 }}>
            <span style={{ opacity: 0.4, marginRight: 4 }}>»</span> Roadmap
          </LevelTwoNav>
          <LevelTwoNav href="#reduceconfusion" style={{ marginBottom: 4 }}>
            <span style={{ opacity: 0.4, marginRight: 4 }}>»</span> Reduce
            confusion
          </LevelTwoNav>
          <LevelTwoNav href="#buildvsbuy" style={{ marginBottom: 4 }}>
            <span style={{ opacity: 0.4, marginRight: 4 }}>»</span> Build vs Buy
          </LevelTwoNav>
          <LevelTwoNav href="#designtokens" style={{ marginBottom: 4 }}>
            <span style={{ opacity: 0.4, marginRight: 4 }}>»</span> Design
            tokens
          </LevelTwoNav>
          <LevelTwoNav href="#contribution" style={{ marginBottom: 4 }}>
            <span style={{ opacity: 0.4, marginRight: 4 }}>»</span> Contribution
            model
          </LevelTwoNav>
          <LevelTwoNav href="#typography" style={{ marginBottom: 24 }}>
            <span style={{ opacity: 0.4, marginRight: 4 }}>»</span> Typography
          </LevelTwoNav>
          <StyledLinkButton href="#designsprints">
            Design sprints
          </StyledLinkButton>
          <StyledLinkButton href="#storyboards">Story telling</StyledLinkButton>
          <StyledLinkButton href="#technology">Technology</StyledLinkButton>
          <StyledLinkButton href="#codereminder">
            Code reminders
          </StyledLinkButton>
        </AsideSticky>
      </Aside>
      <CaseStudiesContainer large center>
        <LeftAligned>
          <BlogSectionVerticalSpace>
            <ScrollToSection id="designsystems">
              <CaseStudiesDesignSystems />
            </ScrollToSection>
            <ScrollToSection id="designsprints">
              <CaseStudiesDesignSprint />
            </ScrollToSection>
            <ScrollToSection id="storyboards">
              <CaseStudiesStoryboards />
            </ScrollToSection>
            <ScrollToSection id="technology">
              <CaseStudiesTechnology />
            </ScrollToSection>
            <ScrollToSection id="codereminder">
              <CaseStudiesCodeReminders />
            </ScrollToSection>
          </BlogSectionVerticalSpace>
        </LeftAligned>
      </CaseStudiesContainer>
    </Grid>
  );
};

export default CaseStudies;
