import React from "react";
import styled from "styled-components";
import Heading from "../../Heading";

const ColumnWrapper = styled.div``;

const ColumnTitle = styled(Heading)`
  margin-top: var(--spacing-clear);
  text-transform: uppercase;
  position: sticky;
  top: 0;
  background: #252525;
  top: 4rem;
  padding-bottom: var(--spacing-small);
  box-shadow: 0 0 var(--spacing-small) 0 #252525;
  margin-bottom: 0;
  &:before {
    content: "";
    width: 100%
    height: 4rem;
    background: #252525;
    position: absolute;
    top: -4rem;
    
  }
`;

const ColumnTitleNumber = styled.span`
  color: #ff73df;
`;

const ColumnHeadingParagraph = styled.p`
  padding-left: 1px;
`;

const ColumnHeading = ({ titleNumber, title, paragraph, className }) => {
  return (
    <ColumnWrapper
      title={title}
      titleNumber={titleNumber}
      paragraph={paragraph}
      className={className}
    >
      <ColumnTitle variant="small">
        <ColumnTitleNumber>{titleNumber}</ColumnTitleNumber> {title}
      </ColumnTitle>
      {paragraph && (
        <ColumnHeadingParagraph>{paragraph}</ColumnHeadingParagraph>
      )}
    </ColumnWrapper>
  );
};

export default ColumnHeading;
