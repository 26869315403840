import React from "react";
import styled from "styled-components";
import SkillsItem from "./SkillsItem";
import Container from "../../Container";
import SkillsImage from "../../../img/skills-hearts.png";

const SkillsContainer = styled(Container)`
  max-width: 120rem;
`;

const PrimarySkillsItem = styled(SkillsItem)`
  padding-top: 2.8rem;
  margin-bottom: 7.2rem;
  @media only screen and (min-width: 500px) {
    max-width: 72rem;
    margin-left: auto;
    margin-right: auto;
    background: url("${SkillsImage}") 0px 0px no-repeat;
    background-position: 50% 1.2rem;
    background-size: 25.2rem;
  }
  @media only screen and (min-width: 768px) {
    background-size: 30rem;
    p {
      font-size: 1.8rem;
    }
  }
  @media only screen and (min-width: 850px) {
    background-size: 34rem;
  }
`;

const SkillsWrapper = styled.div`
  display: grid;
  grid-column-gap: 48px;
  grid-template-columns: repeat(1, 1fr);
  @media only screen and (min-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (min-width: 850px) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 60px;
  }
`;

const Skills = () => {
  return (
    <React.Fragment>
      <SkillsContainer large center>
        <PrimarySkillsItem
          class="primarySkillsItem"
          title="Web Design"
          icon="rgbaIcon"
          description="I love design &amp; I have 13 years of web experience under my belt. Throughout the years, my interest &amp; skills have grown naturally for other areas of web."
        />
        <SkillsWrapper>
          <SkillsItem
            title="HTML"
            icon="htmlIcon"
            description="I have great knowledge of HTML, which lets me create well structured &amp; meaningful mark-up."
          />

          <SkillsItem
            title="CSS"
            icon="cssIcon"
            description="I have a deep understanding of CSS, and this allows me to create layouts in the best possible way."
          />

          <SkillsItem
            title="AGILE"
            icon="agileIcon"
            description="I have years of experience working in an agile environment & collaborate well with others to see a project be successful."
          />

          <SkillsItem
            title="LEAN UX"
            icon="uxIcon"
            description="I have a toolkit of UX techniques that enables me to create, test, discover &amp; solve customer and business problems."
          />

          <SkillsItem
            title="JQUERY"
            icon="jqueryIcon"
            description="Learning jQuery enabled me to craft the interactions of websites I have designed, which is used to bring delight to users."
          />

          {/* <SkillsItem
            title="GITHUB"
            icon="jqueryIcon"
            description="Learning jQuery enabled me to craft the interactions of websites I have designed, which is used to bring delight to users."
          /> */}

          <SkillsItem
            title="SASS"
            icon="sassIcon"
            description="I have a great understanding of Sass &amp; I will comfortably design &amp; write my code with this wonderful language."
          />

          <SkillsItem
            title="XCODE"
            icon="xcodeIcon"
            description="Over the past year, I have focused on learning the front-end side of Xcode, which has allowed me to implement my designs."
          />

          <SkillsItem
            title="RAILS"
            icon="railsIcon"
            description="I have experience working with Rails to make changes and modifications to the front-end on projects."
          />

          <SkillsItem
            title="REACT"
            icon="reactIcon"
            description="I create reusable components that consist of semantic HTML, visual and responsive styles and required state for components to operate in applications."
          />
          <SkillsItem
            title="VUE"
            icon="vueIcon"
            description="My natural curiosity enables me to easily pick-up other modern Javascript-based frameworks to create reusable components for building web applications."
          />
          <SkillsItem
            title="WEB COMPONENTS"
            icon="webComponentsIcon"
            description="My experience in creating native web components using HTML, CSS and JS. My focus into this area has helped me learn how to structure components in a self-contained approach that can be ported to other javascript frameworks."
          />

          <SkillsItem
            title="WHAT'S NEXT?"
            icon="nextIcon"
            description="I strive to learn new skills daily, which allows me to be highly involved from concept to finish."
          />
        </SkillsWrapper>
      </SkillsContainer>
    </React.Fragment>
  );
};
export default Skills;
