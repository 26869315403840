import React from "react";
import styled, { css } from "styled-components";

const flexStyles = (direction, spacing) => css`
  display: flex;
  flex-direction: ${direction === "vertical" ? "column" : "row"};
  gap: ${spacing};
`;

const gridStyles = (direction, spacing) => css`
  display: grid;
  grid-auto-flow: ${direction === "vertical" ? "row" : "column"};
  gap: ${spacing};
`;

const getStackStyles = (variant, direction, spacing) => {
  return variant === "flex"
    ? flexStyles(direction, spacing)
    : gridStyles(direction, spacing);
};

const StackInner = styled.div`
  ${({ variant = "flex", direction = "vertical", spacing = "0" }) =>
    getStackStyles(variant, direction, spacing)}
`;

const Stack = (props) => {
  const { variant, direction, spacing, children, className, disableMargin } =
    props;
  return (
    <StackInner
      variant={variant}
      direction={direction}
      spacing={spacing}
      className={className}
      style={{ margin: disableMargin ? "0" : undefined }}
    >
      {children}
    </StackInner>
  );
};

export default Stack;
