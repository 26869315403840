import React from "react";
import styled from "styled-components";
import Icons from "../../../Icons";
import Badge from "../../../Badge";
import ProjectTitle from "../ProjectTitle";
import ProjectSection from "../ProjectSection";
import ProjectSectionSkills from "../ProjectSectionSkills";
import ProjectDescription from "../ProjectDescription";
import ProjectHeroImage from "../ProjectHeroImage";
import NgaImage from "../../../../img/nga.jpg";

const NgaProjectSection = styled(ProjectSection)`
  background: #009a94;
`;

function Nga() {
  return (
    <NgaProjectSection>
      <ProjectTitle title="Ngā Taonga" />

      <ProjectSectionSkills>
        <Badge small title="UI Design">
          <Icons type="rgba-small" />
        </Badge>
        <Badge small title="HTML">
          <Icons type="html-small" />
        </Badge>
        <Badge small title="Sass">
          <Icons type="sass-small" />
        </Badge>
        <Badge small title="Agile Process">
          <Icons type="agile-small" />
        </Badge>
        <Badge small title="UX Design">
          <Icons type="ux-small" />
        </Badge>
      </ProjectSectionSkills>
      <ProjectDescription>
        I lead the design & front-end to build a deserving mobile-first
        experience, which complements the vibrant content found within the walls
        at Ngā Taonga Sound & Vision.
      </ProjectDescription>

      <ProjectHeroImage src={NgaImage} desktop />
    </NgaProjectSection>
  );
}

export default Nga;
