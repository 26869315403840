import React from "react";
import styled from "styled-components";
import Container from "../../Container";

const ProjectSectionElement = styled.section`
  position: relative;
  box-shadow: inset 0 1em 0 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 0;
`;

const ProjectContainer = styled(Container)`
  padding-bottom: 0;
`;

const ProjectSection = ({ className, children }) => {
  return (
    <ProjectSectionElement className={className} children={children}>
      <ProjectContainer medium center>
        {children}
      </ProjectContainer>
    </ProjectSectionElement>
  );
};

export default ProjectSection;
